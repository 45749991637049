@use '@styles/settings/_variables.scss' as var;

.pd-service-accordion {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    border-bottom: 1px solid #EAECF0;
    cursor: pointer;

    &-title {
      color: #101828;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;

      @media screen and (min-width: 1024px) {
        font-size: 22px;
      }
    }
  }

  .pd-service-accordion-icon-expanded {
    display: none;
  }

  .pd-service-accordion-icon-collapsed {
    color: var.$color-electric-blue;
    display: block;
  }

  &.is-expanded {
    padding-bottom: 24px;
    border-bottom: 1px solid #EAECF0;

    .pd-service-accordion-header {
      border-bottom: none !important;
      padding-bottom: 16px;

      .pd-service-accordion-header-title {
        color: var.$color-dark-blue-2;
      }
    }

    .pd-service-accordion-icon-expanded {
      display: block;
      color: var.$color-electric-blue;
    }

    .pd-service-accordion-icon-collapsed {
      display: none;
    }
  }

  &-content {
    max-width: 90%;
    font-family: var.$font-figtree;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.005em;
    text-align: left;
    margin-bottom: 0;
    color: var.$color-gray-2;

    p {
      margin-bottom: 10px;
      font-family: var.$font-figtree;
    }
  }
}

.pd-service-accordion:not(.is-expanded) {
  .pd-service-accordion-header {
    &::after {
      transform: rotate(135deg);
    }
  }

  .pd-service-accordion-content {
    display: none;
  }
}

.pd-service-accordion:last-child {

  .pd-service-accordion-header {
    border-bottom: none !important;
  }

  &.is-expanded {
    border-bottom: none !important;
  }
}


.pd-service-accordion-section {
  padding-bottom: 120px;
  background-color: var.$color-light-blue-2;

  &-container {
    padding: 32px 20px;
    border-radius: 20px;
    background-color: var.$color-white;
  }
  
  @media screen and (min-width: 1024px) {
    padding: 48px 50px 120px 50px;

    &-container {
      padding: 96px 145px;
    }
  }
}