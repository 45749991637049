@use '@styles/settings/_variables.scss' as var;

.pd-contact {
  background-color: var.$color-background;

  .pd-contact-wrapper {
    background-color: var.$color-white;
  }

  .pd-contact-container {
    padding-top: 64px;
    padding-bottom: 48px;

    @media screen and (min-width: 1024px) {
      padding-bottom: 88px;
    }


    .pd-contact-container-text {
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 32px;
    }

    .pd-contact-form {
      display: grid;
      gap: 32px;

      .pd-contact-form-textarea {
        textarea {
          height: 179px !important;
        }
      }

      @media screen and (min-width: 1024px) {
        grid-template-columns: auto auto;
        .pd-contact-form-textarea {
          grid-column: span 2;
        }
      }

      .pd-contact-form-label {
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        text-align: left;
        color: var.$color-gray-3;
      }

      .pd-contact-form-input {
        width: 100%;
        height: 68px;
        padding: 16px;
        border: 1px solid #D0D5DD;
        font-family: var.$font-poppins;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0.005em;
        text-align: left;
        color: #BCBCBC;

        &:hover {
          border: 1px solid var.$color-electric-blue;
        }

        &:focus-visible {
          border: 1px solid var.$color-electric-blue;
          outline: none;

          &::-webkit-input-placeholder {
            color: transparent;
          }

          &::placeholder {
            color: transparent;
          }
        }
      }

      .pd-contact-form-select {
        position: relative;

        .pd-contact-form-input {
          background-color: var.$color-white !important;
          color: #BCBCBC !important;
          font-size: 18px;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 0.005em;
          text-align: left;
        }

        .pd-contact-form-select-icon {
          color: var.$color-electric-blue;
          position: absolute;
          right: 16px;
          top: 60%;
        }
      }

      .pd-contact-form-btn {
        display: flex;
        button {
          width: 100%;
        }

        @media screen and (min-width: 1024px) {
          justify-content: end;
          grid-column: span 2;
          button {
            width: fit-content;
          }
        }
      }
    }
  }
}