@use '@styles/settings/_variables.scss' as var;

.pd-choosen-vanity {
  width: 100%;
  @media screen and (max-width: var.$breakpoint-phone-max) {
    position: relative;

    &.is-closed {
      .pd-choosen-vanity-item {
        display: none;
      }

      .pd-choosen-vanity-header {
        &::after {
          transform: rotate(-45deg);
          top: 10px;
        }
      }

      .pd-choosen-vanity-header__cta {
        display: none;
      }
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    width: 100%;
    @media screen and (max-width: var.$breakpoint-phone-max) {
      margin: 0;
      padding: 13px 0;

      &::after {
        content: '';
        display: inline-block;
        width: 5px;
        height: 5px;
        border-left: 1px solid #3F515B;
        border-bottom: 1px solid #3F515B;
        transform: rotate(135deg);
        position: relative;
        top: 12px;
        right: 3px;
      }
    }
  }

  &-header__cta {
    color: var.$color-primary;
    @media screen and (max-width: var.$breakpoint-phone-max) {
      position: absolute;
      right: 0;
      bottom: 14px;
      z-index: 3;
    }
  }
}

.pd-choosen-vanity-item {
  background-color: white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  padding: 8px;
  display: flex;
  @media screen and (max-width: var.$breakpoint-phone-max) {
    margin: 0 -10px;
    position: relative;
    z-index: 2;
    padding: 16px 8px;
  }

  &__image {
    display: flex;
    align-items: flex-start;
    min-width: 35%;
    max-width: 35%;
    @media screen and (max-width: var.$breakpoint-phone-max) {
      min-width: 65px;
      max-width: 65px;
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    padding: 0 12px 0 23px;
    @media screen and (max-width: var.$breakpoint-phone-max) {
      padding: 0 0 0 11px;

      p {
        margin-bottom: 11px;
      }
    }
  }

  &__title {
    color: #232423;
    font-size: 20px;
    line-height: 1.5;
    letter-spacing: .4px;
    @media screen and (max-width: var.$breakpoint-phone-max) {
      font-size: 16px;
    }
  }

  &__price {
    color: #6989A3;
    font-size: 16px;
  }
}