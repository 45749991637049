@use '@styles/settings/_variables.scss' as var;

.pd-cart-item {
  display: flex;
  gap: 16px;

  &-wrapper {
    padding: 24px;
    border-bottom: 1px solid var.$color-body-bg;

    .pd-accordion {
      margin-top: 16px;
    }
  }

  p {
    margin: 0;
  }

  &__image {
    position: relative;
    min-width: 82px;
    max-width: 82px;
    height: 82px;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__info {
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-right: 20px;
  }

  &__title {
    color: var.$color-base;
    font-size: 16px;
    line-height: 1.5;
  }

  &__sku {
    color: #707980;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: .72px;
  }

  &__prices {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #232423;
    font-size: 12px;

    .__old-price {
      color: #CEAAB0;
      text-decoration: line-through;
    }
  }

  &__button-remove {
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    background: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;

    svg {
      width: 15px;
    }

    path {
      fill: #3F515B;
    }
  }

  .pd-quantity {
    margin-top: 20px;
  }
}