@use '@styles/settings/_variables.scss' as var;

.pd-carousel-tabs {
  margin-top: 69px;
  
  &-heading {
    margin-bottom: 2.4rem;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;
  }
}