@use '@styles/settings/_variables.scss' as var;

.pd-vanity-selectors {
  position: relative;
  @media screen and (max-width: var.$breakpoint-phone-max) {
    padding: 40px 0;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;
    padding-top: 28px;
  }
}

.pd-vanity-selector-block {
  margin-bottom: 46px;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.pd-selector {
  margin: 40px 0;

  &--sub {
    margin: 16px 0 0;

    .pd-selector-header {
      border: none;
      padding: 0;

      &::before {
        display: none;
      }
    }
  }

  &-header {
    position: relative;
    border-bottom: 1px solid var.$color-primary;
    padding-bottom: 12px;
    color: #354E66;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    text-transform: capitalize;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: 10px;
      right: 5px;
      width: 5px;
      height: 5px;
      border-left: 1px solid #3F515B;
      border-bottom: 1px solid #3F515B;
      transform: rotate(-45deg);
    }

    span {
      font-weight: 600;
    }
  }
}

.pd-selector:not(.pd-selector--sub):not(.is-expanded) {
  .pd-options {
    display: none;
  }

  .pd-selector--sub {
    display: none;
  }
}

.pd-options {
  margin-top: 18px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.pd-option {
  width: calc((100% - 32px) / 5);
  padding-right: 8px;

  &.text-only {
    width: fit-content;
    padding-right: 0;

    label {
      border: 1px solid #D8DDE1;
      border-radius: 3px;
      padding: 2px 10px;
      font-size: 16px;
      line-height: 1.5;
    }

    input:checked + label {
      border-color: var.$color-primary;
    }
  }

  label {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
  }

  input[type="radio"] {
    display: none;
  }

  input:checked + label {
    img {
      border: 1px solid #071A2D;
    }
  }
}

.pd-addons {
  flex-wrap: unset;
  gap: 16px;
  max-width: 100%;
  overflow-x: auto;
}

.pd-addon {
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 112px;
  max-width: 112px;
  padding-right: 0;

  &__title {
    color: black;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5;
  }

  &__price {
    flex-grow: 1;
    font-size: 16px;
    font-weight: 400;
  }

  &__add-button {
    width: 100%;
    border: 0;
    border-radius: 3px;
    background-color: var.$color-secondary;
    padding: 4px 5px;
    color: #071A2D;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
  }
}