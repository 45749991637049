@use '@styles/settings/_variables.scss' as var;

.pd-button {
  font-family: "Poppins", sans-serif;
  border-radius: 100px;
  padding: 0 19px;
  height: 51px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  letter-spacing: 0.1em;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    text-decoration: none;
  }

  &:focus,
  &:focus-visible {
    outline: none;
  }

  &--outline {
    border: 1px solid var.$color-electric-blue;
    background-color: transparent;
    color: var.$color-electric-blue;

    &:hover {
      background-color: 3px solid var.$color-light-gray;
    }
  }

  &--filled-electric-blue {
    border: 1px solid var.$color-electric-blue;
    background-color: var.$color-electric-blue;
    color: var.$color-white;
    &:hover {
      outline: 3px solid var.$color-light-blue-3;
    }
    &:active {
      border: none;
      background-color: var.$color-dark-blue;
    }
  }

  &--filled-dark-blue {
    border: 1px solid var.$color-dark-blue;
    background-color: var.$color-dark-blue;
    color: var.$color-white;
  }

  &--filled-light-blue {
    border: 1px solid var.$color-light-blue;
    background-color: var.$color-light-blue;
    color: var.$color-electric-blue;
    &:hover {
      outline: 3px solid #FFFFFFCC;
    }
    &:active {
      padding: 19px !important;
      border: 1px solid var.$color-dark-blue;
      background-color: var.$color-dark-blue;
      color: var.$color-white;
    }
  }

  &--small {
    width: 100%;
    border: 0;
    border-radius: 3px;
    background-color: var.$color-secondary;
    padding: 15px;
    color: var.$color-base;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
  }

  @media (min-width: 1024px) {
    font-size: 16px;
  }
}

.pd-submit-button {
  width: 100%;
  border: 0;
  border-radius: 3px;
  background-color: var.$color-primary;
  padding: 18px 40px;
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #7895ac;
  }
}
