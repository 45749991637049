$breakpoint-tablet: 768px !default;
$breakpoint-desktop: 990px !default;
$breakpoint-desktop-medium: 1200px !default;
$breakpoint-desktop-large: 1440px !default;
$breakpoint-phone-max: (
    $breakpoint-tablet - 1) !default;
$breakpoint-tablet-max: (
    $breakpoint-desktop - 1) !default;
$breakpoint-desktop-max: (
    $breakpoint-desktop-medium - 1) !default;
$breakpoint-desktop-medium-max: (
    $breakpoint-desktop-large - 1) !default;

$font-poppins: "Poppins", sans-serif;
$font-figtree: "Figtree", sans-serif;

$color-base: #071A2D;
$color-primary: #354E66;
$color-secondary: #C5D0D8;
$color-body-bg: #F7F8F9;
$color-text-light: #3F515B;

$color-white: #FFFFFF;
$color-black: #000000;
$color-black-1: #1A1A1A;
$color-electric-blue: #000596;
$color-light-blue: #D6D6E6;
$color-light-blue-2: #EEEFF4;
$color-light-blue-3: #D8E2F580;
$color-dark-blue: #1C1B3F;
$color-dark-blue-2: #101828;
$color-ocean-blue: rgba(216, 226, 245, 0.5);
$color-gray: #646569;
$color-light-gray: #F8F8F8;
$color-gray-2: #43494D;
$color-gray-3: #6C7475;
$color-skin: #E7D8C7;
$color-off-white: #ECEBE9;
$color-off-white: #ECEBE9;
$color-primary-50: #EBE6EC;
$color-background: #FAFAFA;

