.service-banner-drawer {
  &.pd-drawer {
    justify-content: flex-end;
    opacity: 0;
    transition: unset;

    &.is-open {
      opacity: 1;
    }
  }

  @media screen and (min-width: 990px) {
    .pd-drawer-container {
      min-width: 1000px;
    }
  }

  .header {
    border-bottom: 1px solid #ebe6ec;
    padding: 24px;
    display: flex;
    justify-content: flex-end;

    button {
      border: 0;
      padding: 0;
      background-color: #fff;
      width: max-content;
      margin-left: auto;

      &:active,
      &:focus {
        box-shadow: 0;
        border: 0;
        outline: none;
      }
    }
  }

  .content {
    padding: 48px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 48px;

    @media screen and (max-width: 990px) {
      flex-direction: column;
    }

    .lhs {
      min-width: 300px;
      @media screen and (max-width: 990px) {
        width: 100%;
      }

      p {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0.05em;
        padding-bottom: 16px;
        border-bottom: 1px solid #d5d5d5;
        margin: 0;
      }

      h3 {
        margin: 0;
        margin-top: 16px;
        font-family: Poppins;
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: 0.05em;
      }
    }

    .rhs {
      font-family: Poppins;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      max-height: 82vh;
      overflow-y: scroll;
      
      &::-webkit-scrollbar {
        width: 8px;
      }

      ul {
        margin-top: 0.8rem;
      }
    }
  }
}
