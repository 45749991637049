@use '@styles/settings/_variables.scss' as var;

.pd-footer {
  .pd-main-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    padding-top: 64px;
    padding-bottom: 64px;

    .footer-brand {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 32px;

      .footer-contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        text-align: center;

        .footer-contact-description {
          margin-bottom: 16px;
          max-width: 335px;
        }

        .footer-phone-number, .footer-social-contact {
          color: #000000 !important;
          display: flex;
          gap: 16px;

          &:hover {
            text-decoration: none;
          }

          .footer-phone {
            font-size: 16px;
            font-weight: 400;

            @media screen and (min-width: 1024px) {
              font-size: 18px;
            }
          }

          .footer-social-link {
            color: #000000 !important;
          }
        }
      }
    }

    .footer-practice-hours {
      letter-spacing: 0em;
      text-align: left;
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;

      .footer-practice-hours-title {
        font-size: 18px;
        font-weight: 500;
      }

      .footer-practice-hours-calendar {
        display: flex;
        flex-direction: column;
        gap: 16px;
        font-size: 16px;
        color: var.$color-gray-3;

        .footer-practice-hours-item {
          display: flex;

          .footer-practice-hours-day-of-the-week {
            width: 108px;
          }

          .footer-practice-hours-time-slot {
            font-weight: 500;
          }
        }
      }
    }

    @media screen and (min-width: 1024px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: start;

      .footer-brand {
        display: flex;
        flex-direction: row;
        align-items: start;
        gap: 24px;

        .footer-contact {
          max-width: max-content;
          border-left: 1px solid var.$color-electric-blue;
          padding-left: 24px;
          align-items: start;
          text-align: start;
        }
      }

      .footer-practice-hours {
        min-width: 396px;
        align-items: start;

        .footer-practice-hours-calendar {
          font-size: 18px;

          .footer-practice-hours-item {
            .footer-practice-hours-day-of-the-week {
              width: 116px;
            }
          }
        }
      }
    }
  }

  .pd-secondary-footer {
    color: var.$color-white;
    background: var.$color-black;

    .pd-secondary-footer-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      padding-top: 24px;
      padding-bottom: 24px;
      font-size: 18px;

      .pd-secondary-footer-copyright {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;

        .pd-secondary-footer-copyright-text {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .pd-footer-terms-and-conditions {
          text-decoration: none;
          color: var.$color-white;

          &:hover {
            color: #D6D6E6;
          }

          &:focus {
            color: #D6D6E6;
            text-decoration: underline;
          }

          &:active {
            color: #D6D6E6;
            text-decoration: underline;
          }
        }
      }

      @media screen and (min-width: 1024px) {
        flex-direction: row-reverse;
        justify-content: space-between;

        .pd-secondary-footer-copyright {
          flex-direction: row;
          gap: 24px;

          .pd-secondary-footer-copyright-text {
            flex-direction: row;
            gap: 6px;
          }
        }
      }
    }
  }
}