.pd-accordion {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 0;
    border-top: 1px solid #D8DDE1;
    border-bottom: 1px solid #D8DDE1;
    color: #232423;
    font-size: 12px;
    letter-spacing: .72px;
    cursor: pointer;

    &::after {
      content: '';
      display: inline-block;
      width: 6px;
      height: 6px;
      border-left: 1px solid #3F515B;
      border-bottom: 1px solid #3F515B;
      transform: rotate(-45deg);
      margin-top: -2px;
      margin-right: 3px;
    }
  }

  &-content {
    margin-top: 14px;
    font-size: 12px;

    p {
      margin-bottom: 10px;
    }
  }
}

.pd-accordion:not(.is-expanded) {
  .pd-accordion-header {
    &::after {
      transform: rotate(135deg);
    }
  }

  .pd-accordion-content {
    display: none;
  }
}