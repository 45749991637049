@use '@styles/settings/_variables.scss' as var;

.pd-link {
  color: var.$color-white;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: .72px;
  cursor: pointer;

  &:hover {
    color: var.$color-light-blue;
  }

  &--with-icon {
    &:hover {
      color: var.$color-electric-blue !important;
    }
  }
}
