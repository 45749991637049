@use '@styles/settings/_variables.scss' as var;

.pd-product-card {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  border: 1px solid var.$color-body-bg;

  p {
    margin: 0;
    line-height: 1.5;
  }

  &__image {
    position: relative;
    width: 100%;
    margin-bottom: 8px;

    &::before {
      content: '';
      display: inline-block;
      width: 100%;
      padding-top: 100%;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__price {
    color: var.$color-primary;
    font-size: 19px;
  }

  &__title {
    flex-grow: 1;
    color: var.$color-primary;
    font-size: 16px;
  }
}