@use '@styles/settings/_variables.scss' as var;

.pd-quantity {
  width: 97px;
  height: 37px;
  border-radius: 4px;
  border: 1px solid #D8DDE1;
  display: flex;

  &-button {
    min-width: 32px;
    max-width: 32px;
    border: 0;
    background: none;
    color: var.$color-base;
    font-family: var.$font-poppins;
    font-weight: 400;
    text-align: center;
  }

  input {
    max-width: 33px;
    border: 0;
    text-align: center;
    color: var.$color-base;
    font-family: var.$font-poppins;
    font-size: 12px;
    font-weight: 400;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
  }
}