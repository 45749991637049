@use '@styles/settings/_variables.scss' as var;

.pd-builder-summary {
  display: flex;
  @media screen and (max-width: var.$breakpoint-phone-max) {
    flex-direction: column;
  }

  &-wrapper {
    position: relative;
    overflow: hidden;
  }

  &__sidebar {
    position: relative;
    width: 329px;
    max-width: 25%;
    padding: 56px 32px 56px 0;
    @media screen and (max-width: var.$breakpoint-phone-max) {
      width: 100%;
      max-width: unset;
      padding: 0 0 16px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 100vw;
      height: 100%;
      background-color: var.$color-body-bg;
      @media screen and (max-width: var.$breakpoint-phone-max) {
        display: none;
      }
    }
  }

  &__content {
    flex-grow: 1;
    max-width: 75%;
    padding: 40px 0 40px 4%;
    @media screen and (max-width: var.$breakpoint-phone-max) {
      width: 100%;
      max-width: unset;
      padding: 16px 0;
    }
  }
}
