@use '@styles/settings/_variables.scss' as var;

.pd-selections-summary {
  position: relative;
  @media screen and (max-width: var.$breakpoint-phone-max) {
    &::after {
      content: '';
      position: absolute;
      left: -15px;
      right: -15px;
      bottom: 0;
      border-bottom: 1px solid var.$color-secondary;
    }
  }

  .pd-accordion-header {
    border: 0;
    margin: 0 -15px;
    padding: 13px 15px;
    background: var.$color-body-bg;
    color: var.$color-base;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    @media screen and (min-width: var.$breakpoint-tablet) {
      display: none;
    }
  }

  .pd-accordion-content {
    @media screen and (min-width: var.$breakpoint-tablet) {
      display: block !important;
    }
  }

  &-heading {
    display: flex;
    align-items: flex-start;
    gap: 5px;

    &__title {
      color: #232423;
      font-size: 20px;
      font-weight: 400;
      line-height: 1.5;
    }
  }

  &-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 68px;
    gap: 12px;

    .pd-button {
      width: 100%;
    }

    .pd-link {
      margin-top: 16px;
    }
  }
}

.pd-selections-summary-block {
  margin-top: 54px;
  @media screen and (max-width: var.$breakpoint-phone-max) {
    margin-top: 30px;
  }

  &__title {
    border-bottom: 1px solid var.$color-secondary;
    padding-bottom: 12px;
    color: var.$color-base;
    font-size: 16px;
    font-weight: 700;
  }

  &__value {
    margin: 8px 0;
    color: var.$color-text-light;
    font-size: 16px;
  }
}