@use '@styles/settings/_variables.scss' as var;

.pd-contact-us-banner {
  background-color: var.$color-dark-blue;
  color: var.$color-white;
  padding-top: 200px;
  padding-bottom: 200px;

  @media screen and (min-width: 1024px) {
    padding-top: 88px;
    padding-bottom: 88px;
  }

  .pd-contact-us-banner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;

    .contact-us-banner-text {
      letter-spacing: 0em;
      text-align: center;


      .contact-us-banner-title {
        font-family: var.$font-figtree;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
      }

      .contact-us-banner-description {
        font-family: var.$font-poppins;
        font-size: 32px;
        font-weight: 400;
        line-height: 48px;
        
        @media screen and (min-width: 1024px) {
          font-size: 42px;
          line-height: 63px;
          max-width: 778px;
        }
      }
    }
  }
}