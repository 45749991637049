.site-header-main .swym-wishlist {
  color: #818994;
  font-size: inherit;
  top: 0;
  left: 0;
  position: relative;
  text-decoration: none
}

.site-account-links {
  list-style-type: none
}

.site-account-links li {
  display: inline
}

.site-account-links a {
  color: #fff
}

.productgrid--items .package-item .money {
  display: none
}

@media (min-width: 1024px) {
  header .small-promo-heading {
    font-size: 1.5rem;
    font-weight: normal
  }
}

.special-pricing .swym-product-price {
  display: none !important
}

.ais-page.ais-page .ais-hit--price {
  min-height: 22px
}

.ais-page.ais-page .ais-Pagination-list {
  margin-bottom: 20px
}

.ais-page.ais-page .ais-Pagination-item {
  margin-bottom: 5px
}

.ais-page.ais-page .ais-Pagination-item--selected {
  background: #eee;
  border-color: transparent
}

.ais-page.ais-page .ais-Pagination-item--selected a {
  color: #666;
  cursor: default
}

.ais-page.ais-page .ais-Pagination-item--selected a:hover {
  text-decoration: none
}

.tick-list {
  padding-left: 5px
}

.tick-list li {
  list-style-type: none
}

.tick-list li:before {
  content: '\f00c';
  font-family: 'FontAwesome';
  margin-right: 8px
}

.station-tabs-tabset.station-tabs-tabset .station-tabs-tabpanel {
  max-width: 100vw
}

@media (max-width: 719px) {
  .station-tabs-tabset {
    margin: 0 15px !important
  }
}

@media (max-width: 767px) {
  .site-header-main .small-promo {
    display: none
  }
}

@media screen and (max-width: 719px) {
  .site-header-wrapper .site-header-main {
    height: auto
  }

  .site-header-main .site-logo-image {
    max-width: 95px !important
  }

  .site-header-main .site-account-links {
    display: none
  }

  .site-header-main .site-header-logo {
    display: flex;
    align-items: center
  }
}

.swym-wishlist .fa-heart:before {
  color: #818994
}

.site-header-main .site-header-actions {
  display: none
}

@media (max-width: 410px) {
  .site-header-main .live-search {
    width: auto;
    position: relative
  }
}

@media (min-width: 720px) {
  header .site-header-actions {
    margin-top: 17px
  }
}

@media (min-width: 1024px) {
  .site-header-actions .site-header-account-link:last-child {
    margin-right: 35px !important;
    margin-top: 12px !important
  }
}

@media (max-width: 768px) {

  .product-details .product--price,
  .product-details .guarantee {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%
  }

  .product-details .guarantee {
    margin-top: 5px
  }

  .product-details .product-form--regular .product-form--atc-button,
  .product-pricing .v2-atc-button {
    color: #ffffff;
    background: #909478;
    text-transform: uppercase;
    font-weight: 700;
    border-color: #909478;
    font-size: 17px
  }

  .product-details .product-form--regular .product-form--atc-button:hover,
  .product-pricing .v2-atc-button:hover {
    background: #898f67
  }

  .product-details .product-form--regular .product-form--atc-button.smart-payment-enabled {
    padding-top: 0.65em
  }
}

.v2-atc-button {
  width: 100%
}

.product-pricing .v2-atc-button {
  margin: 10px 0 0
}

@media (min-width: 768px) {
  .product-pricing .v2-atc-button {
    display: none !important
  }
}

.product-form--alt .v2-atc-button {
  margin: 0 0 10px
}

.v2-atc-button+.product-customiser-jump-button,
.v2-atc-button+.product-customiser-jump-button:hover {
  color: #354e66;
  background-color: #f7f8f9;
  border: 1px solid #c5d0d8;
  text-decoration: none
}

.product-main .product--price .price--compare-at.visible+.price--main,
.product-recommendations--section .productitem--sale .productitem--price .price--main,
.product-main .product--price .price--compare-at.visible+.price--main,
.productgrid--items .productitem--sale .productitem--price .price--main {
  color: #6faaaa
}

.product-shop-by-range--container .carousel-inner {
  overflow: visible;
  transition: all .2s
}

.product-shop-by-range--container a[class*="carousel-control"] {
  width: unset;
  z-index: 1000;
  cursor: pointer
}

.product-shop-by-range--container a[class*="carousel-control"] span {
  pointer-events: none
}

.product-shop-by-range--container a[class*="carousel-control"] svg {
  transform: scale(1.3) rotate(90deg);
  color: var(--primary-color-2);
  pointer-events: none
}

.product-shop-by-range--container .carousel-control-next.carousel-control-next svg {
  transform: scale(1.3) rotate(-90deg)
}

#shop-by-range-carousel {
  overflow: hidden
}

@media (min-width: 768px) {
  .product-shop-by-range--container .carousel-inner {
    padding: 0 20px
  }
}

.mobile-account-link,
.mobile-contact-link {
  display: none
}

.featured-collection--section .productitem--sale .productitem--price .price--main {
  color: #1E8570
}

#site-mobile-nav .highlights-banners {
  flex-direction: column;
  background-color: var(--primary-color);
  color: white
}

#site-mobile-nav .highlights-banners>*:not(:last-child) {
  margin-bottom: .5rem
}

#site-mobile-nav .highlights-banners-icon {
  height: 100%
}

#site-mobile-nav .highlights-banners-icon .highlights-banners-custom-icon {
  border-radius: 50%;
  background-color: white
}

#site-mobile-nav .highlights-banners-text {
  margin-left: 15px
}

h1 {
  font-weight: bold
}

body,
p,
.productitem--title,
.site-navigation a,
.site-navigation .navmenu-depth-1>li>a,
.site-header-actions,
.site-header-actions .currency-converter-wrapper .form-field-select,
.navmenu-meganav.navmenu-meganav--desktop .navmenu-item-text,
.mobile-nav-content .navmenu-depth-1>.navmenu-item>.navmenu-link,
.site-navigation .navmenu-depth-2 .navmenu-link,
.form-field-input,
.form-field-select,
.productgrid--sidebar-item-grid .filter-item:not(.filter-item--toggle-more),
.product-reviews--content .spr-form .spr-form-input-text,
.product-reviews--content .spr-form .spr-form-input-email,
.product-reviews--content .spr-form .spr-form-input-textarea,
.option-value-input+.option-value-name {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  margin-bottom: 0;
}

.promo-block--header {
  font-family: "Beausite", sans-serif
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

.img-responsive {
  width: 100%;
  max-width: 100%;
  height: auto
}

.product-ratings {
  display: none
}

.brand img {
  width: 100%
}

.hidden {
  display: none
}

.additional-info {
  position: relative;
  display: none
}

.additional-info h2 {
  cursor: pointer;
  border: 1px solid #eee;
  font-size: 20px;
  padding: 10px 5px;
  border-left: 0;
  border-right: 0
}

.additional-info h2.expanded:after {
  transform: rotate(-180deg)
}

.additional-info h2:after {
  position: absolute;
  right: 1rem;
  content: "\f107";
  font-family: FontAwesome;
  text-align: left;
  vertical-align: text-top;
  display: inline-block;
  width: .25rem;
  height: 1.125rem;
  line-height: 1.125rem;
  padding: 0 .5rem 0 0;
  text-transform: none;
  font-size: 1.25rem;
  position: absolute;
  -webkit-transition: all .2s;
  transition: all .2s
}

.additional-info h2:hover {
  background-color: #f7f7f7
}

.additional-info table {
  text-transform: capitalize
}

.additional-info table thead {
  display: none
}

.additional-info.from-tags {
  display: block
}

.additional-info th {
  white-space: nowrap
}

.product-shipping {
  background-color: #F7F8F9
}

.product-shipping .calculate-shipping,
.product-shipping .calculate-shipping:hover,
.product-shipping .calculate-shipping:focus {
  background-color: #848993;
  border: 1px solid #848993
}

.product-shipping .rates,
.product-shipping a {
  font-size: 16px;
  color: var(--primary-color-2)
}

.product-shipping .rate {
  font-weight: bold
}

.product-shipping .additional-shipping-benefits {
  color: #6faaaa;
  font-size: 16px;
  list-style-type: none;
  padding: 0;
  margin: 0
}

.product-shipping .additional-shipping-benefits li {
  padding: 5px 0 5px 35px;
  background-image: url("https://cdn.shopify.com/s/files/1/0014/6938/2771/files/tick.svg?v=1649047068");
  background-repeat: no-repeat
}

.shipping-estimate-response {
  text-align: left
}

.bluespace-customiser .label-required:after {
  color: #ff5d5d;
  content: " *"
}

.bluespace-customiser .product-form--atc-button {
  width: 100%;
  margin-left: 0
}

.bluespace-customiser .form-select-wrapper svg {
  position: absolute;
  top: 50%;
  right: 0.625rem;
  margin-top: -3px;
  pointer-events: none
}

.mega-menu-compare_at_price {
  display: none !important
}

.review-title {
  display: block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  line-height: 1.3;
  color: #484848
}

.site-navigation,
.site-mobile-nav {
  text-align: left;
  font-size: 14px
}

.site-navigation .navmenu-depth-1,
.site-mobile-nav .navmenu-depth-1 {
  display: flex
}

.site-navigation .navmenu-depth-1>li,
.site-mobile-nav .navmenu-depth-1>li {
  margin: 0px;
  padding: 0px
}

.site-navigation .navmenu-depth-1>li:last-child,
.site-mobile-nav .navmenu-depth-1>li:last-child {
  margin-left: auto
}

.site-navigation .navmenu-depth-1>li>.navmenu-link,
.site-mobile-nav .navmenu-depth-1>li>.navmenu-link {
  padding: 15px 20px;
  text-decoration: none;
  transition: all .2s;
  display: flex;
  align-items: center;
  gap: 15px
}

.site-navigation .navmenu-depth-1>li>.navmenu-link:hover,
.site-mobile-nav .navmenu-depth-1>li>.navmenu-link:hover {
  background-color: #000;
  color: white;
  opacity: 1
}

.site-navigation .navmenu-depth-2,
.site-mobile-nav .navmenu-depth-2 {
  margin-left: 0
}

.site-navigation .navmenu-depth-2>.navmenu-item>.navmenu-link,
.site-mobile-nav .navmenu-depth-2>.navmenu-item>.navmenu-link {
  display: flex;
  align-items: center;
  gap: 15px
}

.site-navigation .navmenu-depth-2>.navmenu-item>.navmenu-link .navmenu-icon,
.site-mobile-nav .navmenu-depth-2>.navmenu-item>.navmenu-link .navmenu-icon {
  position: static;
  margin: 0;
  margin-left: auto;
  height: inherit
}

.site-navigation .navmenu-icon svg,
.site-mobile-nav .navmenu-icon svg {
  width: 10px;
  height: 16px
}

.site-navigation .navmenu-icon-img,
.site-mobile-nav .navmenu-icon-img {
  max-width: 60px
}

.site-navigation .navmenu-icon-img img,
.site-mobile-nav .navmenu-icon-img img {
  max-width: 100%;
  display: block;
  height: 24px !important;
  width: 24px;
  min-width: 24px;
  object-fit: contain
}

.site-navigation .navmenu-submenu,
.site-mobile-nav .navmenu-submenu {
  padding: 0;
  background-color: #f7f8f9;
  border: 1px solid #d1d1d1;
  min-width: 20vw
}

.site-navigation .navmenu-submenu .navmenu-link,
.site-mobile-nav .navmenu-submenu .navmenu-link {
  color: var(--primary-color-2);
  padding: 5px 20px
}

.site-navigation .navmenu-submenu .navmenu-link:hover,
.site-navigation .navmenu-submenu .navmenu-link-parent-active,
.site-mobile-nav .navmenu-submenu .navmenu-link:hover,
.site-mobile-nav .navmenu-submenu .navmenu-link-parent-active {
  background-color: #d5dce3;
  color: #000
}

.site-navigation .navmenu-depth-3,
.site-mobile-nav .navmenu-depth-3 {
  top: 0
}

.site-navigation .navmenu-depth-3>.navmenu-item .navmenu-link,
.site-mobile-nav .navmenu-depth-3>.navmenu-item .navmenu-link {
  font-weight: 400 !important
}

.site-navigation .navmenu-depth-1>li>.navmenu-link[aria-expanded="true"] {
  background-color: #000;
  color: white;
  opacity: 1
}

.site-navigation .navmenu-depth-1>li>.navmenu-link .navmenu-icon {
  top: 2px !important
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .site-navigation .navmenu-depth-1 li.navmenu-id-specials {
    display: none
  }
}

.site-mobile-nav .navmenu-depth-1>li>.navmenu-link:hover,
.site-mobile-nav .navmenu-depth-1>li>.navmenu-link-parent-active {
  background-color: transparent;
  color: #6989a3
}

.site-mobile-nav .navmenu-link {
  color: var(--primary-color-2);
  font-weight: bold !important;
  display: flex;
  align-items: center;
  gap: 10px
}

.site-mobile-nav .navmenu-button {
  background: transparent !important;
  margin-right: 20px
}

.site-mobile-nav .navmenu-submenu .navmenu-link {
  padding: 15px 20px
}

.non-home-quicklinks {
  margin-bottom: 30px
}

.non-home-quicklinks [data-rimg-canvas] {
  display: none
}

.non-home-quicklinks [data-menulist-toggle] {
  display: none
}

.swym-wishlist-cta {
  cursor: pointer;
  color: var(--primary-color);
  display: block;
  padding: 15px;
  text-align: center
}

.swym-wishlist-cta:before {
  content: "\f08a";
  font-family: fontAwesome;
  margin-right: 5px
}

.swym-added .swym-wishlist-cta:before {
  content: "\f004"
}

.swym-wishlist-button-bar {
  display: none !important
}

.site-header-sticky--scrolled~#ju_Con_456518 {
  display: none !important
}

.site-header-actions .contact-link {
  position: relative;
  top: 1px;
  left: 7px
}

.site-header-actions .contact-link a {
  font-size: 25px;
  color: #76aaaa
}

.announcement-container {
  max-width: 1400px;
  display: flex;
  margin: 0 auto
}

.announcement-container .first-region {
  text-align: left;
  flex: 0 0 75%;
  max-width: 75%;
  font-size: 14px;
  letter-spacing: 0.28px
}

.announcement-container .second-region {
  text-align: right;
  flex: 0 0 25%;
  max-width: 25%;
  font-size: 13px;
  letter-spacing: 0.13px;
  z-index: 99;
  position: relative
}

.announcement-container .site-account-links {
  margin-bottom: 0
}

.site-header-sticky--scrolled .site-header-actions {
  margin-top: 26px
}

.site-header-sticky--scrolled .site-account-links {
  top: 15px
}

@media (max-width: 1400px) {
  .announcement-container .second-region {
    padding-right: 15px
  }
}

@media (max-width: 719px) {
  .announcement-container .first-region {
    text-align: center;
    flex: 0 0 100%;
    max-width: 100%
  }

  .announcement-container .second-region {
    display: none
  }
}

.site-navigation--has-actions .site-navigation .navmenu-depth-1 {
  max-width: 100%
}

.navmenu-meganav.navmenu-meganav--desktop .navmenu-depth-2 .navmenu-link {
  padding: 0.3rem 0;
  font-size: 14px
}

.navmenu-meganav.navmenu-meganav--desktop .navmenu-depth-2 .navmenu-link-parent {
  font-weight: bold;
  font-size: 16px
}

.navmenu-meganav.navmenu-meganav--desktop .navmenu-depth-2 .navmenu-link-parent:hover {
  color: #709d9e
}

.gallery-zoom {
  color: #6faaaa;
  font-size: 30px;
  position: absolute;
  bottom: 5%;
  z-index: 20;
  right: 5%;
  cursor: pointer
}

.search-flydown--product-price--compare-at {
  display: none
}

.shogun-root {
  overflow: hidden
}

.sale-text {
  color: #c72f2c;
  border: 1px solid #c72f2c;
  font-size: 12px;
  font-weight: bold;
  padding: 3px;
  margin-left: 5px;
  position: relative;
  top: -3px
}

.site-header-actions .site-header-account-link:last-child {
  margin-top: 12px
}

.highlights-banner-text p {
  margin-bottom: 0
}

.low-stock-message {
  color: #ff2a00;
  border-radius: 5px;
  border: 1px solid;
  padding: 10px 10px 0 10px
}

.low-stock-message p {
  font-weight: bold
}

.article--back-btn {
  margin: -10px 0 15px
}

.article--back-btn:hover {
  text-decoration: none
}

@media (min-width: 720px) {
  .article--back-btn {
    margin-left: 25px
  }
}

.article--back-btn i {
  font-size: 80%;
  display: inline-block;
  margin-right: 3px
}

.collection-prod-count-mobile {
  color: var(--primary-color)
}

@media (min-width: 860px) {
  .collection-prod-count-mobile {
    display: none
  }
}

@media (min-width: 1024px) {
  .article--outer-full .article--inner {
    max-width: 100%
  }

  .site-header-sticky--scrolled .site-header-actions .site-header-account-link:last-child {
    margin-top: 8px !important
  }

  .collection-list--section .collection--item {
    width: calc(20% - 15px);
    margin-left: 15px
  }

  .collection-list--section .collection--item:nth-child(4n+1) {
    clear: none
  }

  .collection-list--section .collection--item:nth-child(n+5) {
    margin-top: 0
  }

  .collection-list--section .collection--item:nth-child(n+6) {
    margin-top: 4.125rem
  }
}

@media (max-width: 1023px) {
  .site-header-actions {
    right: 20px;
    position: absolute;
    top: 10px;
    z-index: 999
  }

  .site-header-actions .contact-link {
    top: 28px
  }

  .site-header-main-content .site-header-cart {
    top: 10%;
    position: absolute
  }

  .site-header-sticky--scrolled .site-header-actions .contact-link {
    top: 18px
  }

  .mobile-nav-close {
    z-index: 999
  }

  .mobile-nav-panel {
    max-width: 100%
  }

  .mobile-nav-panel li.site-header-account-link {
    display: inline-block
  }

  .highlights-banners-container {
    overflow: hidden
  }

  .flickity-viewport {
    position: relative
  }
}

.productgrid--wrapper {
  width: calc(100% - 185px)
}

@supports (display: grid) {
  .productgrid--wrapper {
    width: 100%
  }
}

@media screen and (min-width: 860px) {
  .productgrid--sidebar {
    float: left;
    display: inline-block;
    width: 185px
  }
}

.custom-filters-button {
  color: var(--primary-color);
  cursor: pointer;
  display: none
}

@media (min-width: 860px) {
  .collection-split-test-active .productgrid--wrapper {
    max-width: 1380px
  }

  .collection-split-test-active .collection--section .productgrid--outer {
    grid-template-columns: 1fr;
    column-gap: 0px
  }

  .collection-split-test-active .utils-filter {
    display: table-cell
  }

  .collection-split-test-active .productgrid--sidebar,
  .collection-split-test-active .productgrid--utils.utils-filter {
    display: none
  }

  .collection-split-test-active .custom-filters-button {
    display: table-cell;
    width: 40%;
    vertical-align: middle
  }

  .collection-split-test-active .productgrid--wrapper {
    width: 100%;
    border-left: 0;
    padding-left: 0
  }

  .collection-split-test-active .horizontal-filter-bar-wrap {
    display: none
  }

  .collection-split-test-active .horizontal-filter-bar-wrap.show {
    display: block
  }

  .collection-split-test-active .collection--description {
    margin: 0 auto
  }
}

.productgrid--sidebar-section {
  display: block
}

.horizontal-filter-bar-wrap {
  background: #c5d0d8;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 20px;
  max-width: 94.5vw;
  position: relative;
  height: 55px;
  display: none
}

.horizontal-filter-bar-wrap.active .horizontal-fbw-inner {
  height: 70vh
}

.horizontal-filter-bar-wrap .horizontal-fbw-inner {
  position: absolute;
  top: 10px;
  height: 35px;
  width: 97%;
  overflow-x: scroll;
  scrollbar-width: none
}

.horizontal-filter-bar-wrap .horizontal-fbw-inner::-webkit-scrollbar {
  display: none
}

.horizontal-filter-bar-wrap nav {
  display: flex;
  position: absolute;
  top: 0;
  height: 40px;
  white-space: nowrap
}

.horizontal-filter-bar-wrap h3 {
  background: #fff;
  padding: 8px 30px 8px 12px;
  border-radius: 4px;
  position: relative;
  margin: 0 10px 0 0;
  min-width: 120px;
  white-space: nowrap
}

@media (min-width: 768px) {
  .horizontal-filter-bar-wrap h3 {
    min-width: 140px
  }
}

.horizontal-filter-bar-wrap ul.filter-group {
  background: #fff;
  position: absolute;
  top: 29px;
  z-index: 1000;
  min-width: 220px;
  border: 0;
  padding: 0
}

.horizontal-filter-bar-wrap ul.filter-group.active {
  padding: 10px 14px;
  border: 1px solid #000
}

.horizontal-filter-bar-wrap svg {
  position: absolute;
  top: 50%;
  right: 0.625rem;
  margin-top: -3px;
  pointer-events: none;
  color: #354e66
}

.hfbw-inner-wrap {
  position: relative
}

.fbw-arrow {
  position: absolute;
  right: -15px;
  top: 35%;
  cursor: pointer
}

.fbw-arrow.fbw-fa-left {
  right: auto;
  left: -15px
}

.bottom-buy-bar {
  display: none
}

.bottom-buy-bar .btn-specs {
  background-color: #fff;
  color: #4d4d4d !important;
  display: block;
  float: left;
  cursor: pointer
}

.bottom-buy-bar a.btn-buy {
  cursor: pointer;
  display: block;
  float: left;
  color: #fff;
  background-color: #b28b8b
}

.modal {
  overflow-y: auto
}

.modal .modal-close {
  z-index: 99
}

.modal .modal-content {
  border: none
}

.modal .filter-item,
.modal .filter-item:last-child {
  border: none
}

@media screen and (max-width: 719px) {
  .bottom-buy-bar {
    display: block;
    position: fixed;
    width: 100vw;
    bottom: 0;
    font-size: 17px;
    font-weight: bold;
    height: 50px;
    line-height: 50px;
    left: 0;
    text-align: center;
    z-index: 999
  }

  .site-header-cart {
    top: 8px
  }

  .site-header-account-link {
    display: none
  }

  .site-mobile-nav {
    top: 110px
  }

  .site-mobile-nav .site-header-actions {
    right: -10px
  }

  .site-mobile-nav .site-header-actions .contact-link {
    top: -10px
  }

  .site-mobile-nav .site-header-actions .site-header-account-link:last-child {
    margin-right: 0
  }

  .mobile-account-link {
    position: relative;
    margin-right: 0;
    top: 0;
    font-size: 23px;
    color: #818994;
    z-index: 999;
    display: initial !important
  }

  .mobile-account-link:hover {
    text-decoration: unset
  }

  .mobile-contact-link {
    position: relative;
    top: 2px;
    margin-right: 15px;
    font-size: 20px;
    z-index: 999;
    color: #6faaaa;
    display: initial !important
  }

  .mobile-contact-link:hover {
    text-decoration: unset
  }

  .mobile-nav-close {
    display: none
  }

  .mobile-nav-content {
    padding-top: 0
  }
}

.account-logout-link {
  color: #6faaaa
}

@media (min-width: 720px) {
  .mobile-nav-content {
    padding-top: 50px
  }
}

@media (max-width: 1023px) {
  .site-header-logo {
    display: table;
    max-width: calc(calc(50% - 53px) * 2);
    min-height: 46px;
    margin-left: auto;
    margin-right: auto
  }

  .site-logo {
    display: table-cell;
    vertical-align: middle
  }
}

@media (min-width: 720px) and (max-width: 1023px) {
  .site-header-actions .site-header-account-link:last-child {
    margin-right: 14px;
    margin-top: 7px
  }

  .site-header-main .live-search {
    width: 100%;
    margin-top: 20px
  }
}

@media (max-width: 650px) {
  .site-header-logo {
    max-width: calc(calc(50% - 40px) * 2)
  }
}

@media (max-width: 560px) {
  .single-review {
    display: none
  }
}

@media (max-width: 410px) {
  .site-logo img {
    max-height: auto;
    position: relative
  }
}

@media (min-width: 375px) and (max-width: 410px) {
  .site-logo {
    position: relative;
    left: -10px
  }
}

div#swym-tab-modal button.swym-button {
  color: #fff !important;
  background-color: #b28b8b !important;
  border: 1px solid #b28b8b !important
}

form#swym-hashtageditor {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700
}

article.product--outer button.swym-button.swym-add-to-wishlist-view-product {
  display: none
}

.swym-hashtageditor-list-item.disabled label {
  width: 100% !important
}

.swym-button.swym-add-to-wishlist-view-product:not(.swym-loaded) {
  display: none
}

#swym-plugin .swym-price,
#swym-hosted-plugin .swym-price {
  font-size: 11px !important;
  font-weight: 600
}

.add-to-wishlist-region a {
  color: #6faaaa
}

.search-trigger {
  color: inherit;
  text-decoration: none
}

.search-trigger[data-toggle="page"] {
  display: none
}

@media (max-width: 719px) {
  .search-trigger[data-toggle="page"] {
    display: initial
  }
}

@media (max-width: 719px) {
  .search-trigger[data-toggle="modal"] {
    display: none
  }
}

.modal--productgrid-filters .filter-item a {
  width: 100%
}

.gm-menu-installed .site-mobile-nav .gm-menu.gm-menu-mobile>.gm-item,
.gm-menu-installed .site-mobile-nav .gm-menu.gm-menu-mobile>.gm-item:hover,
.gm-menu-installed .site-mobile-nav .gm-menu.gm-menu-mobile>.gm-item:focus {
  background-color: #fff !important
}

.gm-menu-installed .site-mobile-nav .gm-menu .gm-item.gm-level-0>.gm-target>.gm-icon,
.gm-menu-installed .site-mobile-nav .gm-menu .gm-item.gm-level-0>.gm-target>.gm-retractor,
.gm-menu-installed .site-mobile-nav .gm-menu .gm-item.gm-level-0>.gm-target>.gm-text {
  color: var(--primary-color-2) !important;
  background-color: #fff !important
}

.gm-menu-installed .site-mobile-nav .gm-menu .gm-item.gm-level-0.gm-active>.gm-target,
.gm-menu-installed .site-mobile-nav .gm-menu .gm-item.gm-level-0:hover>.gm-target .gm-menu .gm-item.gm-level-0.gm-active>.gm-target,
.gm-menu-installed .site-mobile-nav .gm-menu .gm-item.gm-level-0:hover>.gm-target {
  opacity: .8;
  background-color: #fff !important
}

.gm-menu-installed .site-mobile-nav .gm-menu .gm-item.gm-level-0.gm-active>.gm-target>.gm-icon,
.gm-menu-installed .site-mobile-nav .gm-menu .gm-item.gm-level-0.gm-active>.gm-target>.gm-retractor,
.gm-menu-installed .site-mobile-nav .gm-menu .gm-item.gm-level-0.gm-active>.gm-target>.gm-text,
.gm-menu-installed .site-mobile-nav .gm-menu .gm-item.gm-level-0:hover>.gm-target>.gm-icon,
.gm-menu-installed .site-mobile-nav .gm-menu .gm-item.gm-level-0:hover>.gm-target>.gm-retractor,
.gm-menu-installed .site-mobile-nav .gm-menu .gm-item.gm-level-0:hover>.gm-target>.gm-text {
  color: var(--primary-color-2) !important
}

.gm-item.gm-level-0:last-child {
  margin-left: auto !important
}

.gm-item.gm-level-0:last-child {
  float: right
}

.gm-menu-installed .gm-menu img {
  height: 24px !important
}

.gm-menu-installed .gm-level-2 .gm-submenu {
  max-height: 80vh;
  overflow-y: auto
}

.productgrid--sidebar-title,
.productgrid--sidebar-title--small,
.productitem--title a,
.collection--title {
  color: var(--primary-color-2)
}

@media screen and (min-width: 860px) {
  .productgrid--wrapper {
    border-left: 1px solid var(--primary-color-2);
    padding-left: 30px
  }
}

.productitem .flair-badge-layout {
  position: absolute;
  top: 0;
  flex-direction: column !important;
  align-items: start !important
}

.productitem--image img {
  width: 100%;
  object-fit: contain;
  object-position: center
}

.productitem--info {
  padding: 0.5rem
}

.productitem--info .price--main {
  font-size: 19px !important;
  color: var(--primary-color-2) !important
}

.productitem--info .price--compare-at {
  font-size: 19px !important;
  color: #A0AAB4 !important
}

.productitem--info .price--compare-at .money {
  margin-right: 10px
}

.rrp-money {
  text-decoration: line-through
}

@media screen and (max-width: 576px) {
  .productitem--info .price--compare-at {
    display: none
  }
}

@media screen and (max-width: 576px) {
  .productitem--info .price--compare-at {
    display: none
  }
}

.productitem--info .sale-text {
  display: none
}

.productitem--info .productitem--price .price--compare-at.visible+.price--main {
  margin: 0
}

.price--from {
  color: var(--primary-color-2)
}

.productitem--info .productitem--price {
  display: flex;
  align-items: center
}

.productitem--info button.swym-button.swym-add-to-wishlist-view-product {
  margin-left: 20px
}

.productitem--info .swym-add-to-wishlist-view-product:after {
  color: #a3acb7 !important
}

.site-footer-help-col h2 {
  text-transform: uppercase;
  color: #5CAAAA;
  font-size: var(--font-size-heading-4)
}

.site-footer-help-col ul {
  list-style-type: none;
  font-size: 1.15em;
  font-weight: 600;
  padding-left: 0;
  white-space: normal;
  color: var(--primary-color-2)
}

.show-price-redesign {
  flex-wrap: wrap
}

.show-price-redesign .package-item .discount-info.hidden {
  display: none
}

.show-price-redesign .product--price {
  flex: 100%;
  display: flex;
  align-items: center
}

.show-price-redesign .price--compare-at {
  text-decoration: none;
  order: 2;
  font-size: .875em;
  margin: 0
}

.show-price-redesign .price--compare-at .money.money {
  color: var(--primary-color)
}

.show-price-redesign .price--main {
  font-size: 2em;
  font-weight: 600
}

.show-price-redesign .discount-info.hidden {
  display: block;
  order: 1;
  padding: 0 5px;
  color: var(--primary-color);
  font-weight: 600;
  font-size: .875em
}

.product-pricing .product--price .price--main .money {
  font-size: 22px
}

.product-pricing .product--price .price--main .money .rrp-money {
  font-size: 20px
}

.pp-colour-swatches {
  flex-wrap: wrap
}

.pp-colour-swatches p {
  color: var(--primary-color-2);
  margin-bottom: 5px
}

.productgrid--item .pp-colour-swatches {
  margin-top: 7px
}

.pp-cs-circle {
  display: inline-block;
  background-color: #eee;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  margin: 0 4px 4px 0
}

.pp-cs-circle:nth-child(n+5) {
  display: none
}

.expanded .pp-cs-circle:nth-child(n+5) {
  display: inline-block
}

.pp-cs-total-colours {
  display: flex;
  font-size: .75em;
  color: var(--primary-color-2);
  cursor: pointer;
  flex-wrap: wrap;
  flex: 100%
}

.expanded .pp-cs-total-colours {
  display: none
}

.colour-swatch-chrome {
  background-color: #d8dcdf
}

.colour-swatch-black {
  background-color: #070707
}

.colour-swatch-white {
  background-color: #fff;
  border: 1px solid #ccc
}

.colour-swatch-brushed-nickel {
  background-color: #b5b2a7
}

.colour-swatch-stainless-steel {
  background-color: #b9b5b8
}

.colour-swatch-matte-black {
  background-color: #201816
}

.colour-swatch-golds {
  background-color: #e8c37d
}

.colour-swatch-gunmetal {
  background-color: #716c6b
}

.colour-swatch-satin-chrome {
  background-color: #d4d3d1
}

.colour-swatch-grey {
  background-color: #939599
}

.colour-swatch-brushed-brass {
  background-color: #bcb18f
}

.colour-swatch-wood-finishes {
  background-color: #bc906a
}

.colour-swatch-grey-hues {
  background-color: #7b7b7f
}

.colour-swatch-brass {
  background-color: #e8ca97
}

.colour-swatch-pink-hues {
  background-color: #c6a8ab
}

.colour-swatch-green-hues {
  background-color: #87ab91
}

.colour-swatch-blue-hues {
  background-color: #839aa7
}

.colour-swatch-red-hues {
  background-color: #b54b43
}

.colour-swatch-yellow-hues {
  background-color: #e8d8bd
}

.colour-swatch-satin-brass {
  background-color: #d2b78f
}

.colour-swatch-gun-metal {
  background-color: #5c5d58
}

.colour-swatch-champagne {
  background-color: #d1bca4
}

.colour-swatch-matte-white {
  background-color: #fafafa
}

.colour-swatch-natural-wood {
  background-color: #b8936c
}

.colour-swatch-polished-brass {
  background-color: #ead092
}

.colour-swatch-antique-bronze {
  background-color: #978371
}

.colour-swatch-brushed-gold {
  background-color: #bfae7b
}

.colour-swatch-antique-brass {
  background-color: #927d56
}

.colour-swatch-metallic {
  background-color: #fce1c1
}

.colour-swatch-gold {
  background-color: #e8d384
}

.colour-swatch-pewter {
  background-color: #807572
}

.colour-swatch-antique-black {
  background-color: #494b4f
}

.colour-swatch-rose-gold {
  background-color: #d6a772
}

.colour-swatch-green {
  background-color: #87ab91
}

.colour-swatch-bronze-gold {
  background-color: #c3ba8e
}

.colour-swatch-brushed-gun-metal-grey {
  background-color: #716c6b
}

.colour-swatch-brushed-rose-gold {
  background-color: #d6a772
}

.colour-swatch-gun-metal-grey {
  background-color: #716c6b
}

.colour-swatch-metallic-finishes {
  background-color: #fce1c1
}

.colour-swatch-american-oak {
  background-color: #d8bca1
}

.colour-swatch-blackbutt {
  background-color: #aa765c
}

.colour-swatch-walnut {
  background-color: #6e4e43
}

.colour-swatch-gunmetal-grey {
  background-color: #716c6b
}

.colour-swatch-tiger-bronze {
  background-color: #e8ca97
}

.colour-swatch-black-sapphire {
  background-color: #534e4c
}

.colour-swatch-ivory {
  background-color: #d6d3d4
}

.colour-swatch-metallic-golden-brown {
  background-color: #423a2a
}

.colour-swatch-blue {
  background-color: #839aa7
}

.colour-swatch-brushed-gun-metal {
  background-color: #716c6b
}

.colour-swatch-brushed-stainless-steel {
  background-color: #b9b5b8
}

.colour-swatch-copper {
  background-color: #a2765e
}

.colour-swatch-matte-grey {
  background-color: #9a9ea1
}

.colour-swatch-mint {
  background-color: #87ab91
}

.colour-swatch-pink {
  background-color: #c6a8ab
}

.colour-swatch-blackwood {
  background-color: #4c2d17
}

.colour-swatch-bronze {
  background-color: #978371
}

.colour-swatch-brownwood {
  background-color: #894d2e
}

.colour-swatch-brushed-bronze {
  background-color: #978371
}

.colour-swatch-brushed-copper {
  background-color: #997654
}

.colour-swatch-clear {
  background-color: #e3e6e0
}

.colour-swatch-light-oak {
  background-color: #f3dccb
}

.colour-swatch-mint-green {
  background-color: #97a98f
}

.colour-swatch-polished-rose-gold {
  background-color: #d6a772
}

.colour-swatch-polished-stainless-steel {
  background-color: #b9b5b8
}

.colour-swatch-tan {
  background-color: #bc8163
}

.colour-swatch-wood {
  background-color: #b8936c
}

.colour-swatch-wood-grain {
  background-color: #b8936c
}

.colour-swatch-brushed-carbon {
  background-color: #585453
}

.colour-swatch-shadow {
  background-color: #767571
}

.colour-swatch-walnut {
  background-color: #773E21
}

.colour-swatch-oak {
  background-color: #D6A967
}

.colour-swatch-maple {
  background-color: #937154
}

.colour-swatch-whitewashed-timber {
  background-color: #B9AA98
}

.colour-swatch-aluminium {
  background-color: #B1B3B4
}

.colour-swatch-titanium {
  background-color: #6B6B6B
}

.colour-swatch-bamboo {
  background-color: #BB936A
}

.colour-swatch-koa {
  background-color: #3C2C29
}

.colour-swatch-elm {
  background-color: #53392D
}

.colour-swatch-light-walnut {
  background-color: #9D7052
}

.colour-swatch-stainless-steel {
  background-color: #CFCFCF
}

.colour-swatch-teak {
  background-color: #7c3f21
}

.colour-swatch-charcoal {
  background-color: #3b3b3b
}

.colour-swatch-beige {
  background-color: #cdb38b
}

.colour-swatch-greige {
  background-color: #b7ab9f
}

.colour-swatch-cream {
  background-color: #ffefd5
}

.colour-swatch-brown {
  background-color: #8b7355
}

.colour-swatch-terracotta {
  background-color: #cd661d
}

.colour-swatch-teal {
  background-color: #008080
}

.colour-swatch-stone {
  background-color: #ddd8d6
}

.colour-swatch-ivory {
  background-color: #cdba96
}

.built-product-view .sticky-top {
  top: 15px;
  padding: 0 15px 15px 15px;
  border-radius: 5px;
  border: 1px solid #6989A3;
  background-color: #fff
}

.built-product-view .fa-times-circle {
  color: #6989A3;
  cursor: pointer
}

.bpv-inner {
  max-height: 64vh;
  overflow-y: auto;
  margin: 0 -15px;
  padding: 0 15px 15px
}

.bpv-footer {
  position: relative;
  background: #f7f8f9;
  margin: 0 -15px -15px;
  padding: 10px 15px 15px;
  border-radius: 0 0 4px 4px
}

.bpv-footer:before {
  content: "";
  top: -20px;
  right: 0;
  position: absolute;
  width: 100%;
  z-index: 10;
  pointer-events: none;
  height: 20px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0, #fff, #fff)
}

.bpv-footer .total-price {
  font-weight: bold
}

.promo-mosaic--inner.promo-mosaic--inner .promo-block--content-wrapper {
  text-align: left
}

.promo-mosaic--inner.promo-mosaic--inner .promo-block--header {
  margin-left: 0;
  font-family: 'Manrope', sans-serif;
  font-weight: 600
}

.promo-mosaic--inner.promo-mosaic--inner .promo-block--text {
  margin-left: 0
}

.link-list--container {
  max-width: 1400px;
  margin: 20px auto 0
}

.link-list--inner {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  padding: 0 8px;
  grid-gap: 7px
}

@media (min-width: 640px) {
  .link-list--inner {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }
}

@media (min-width: 860px) {
  .link-list--inner {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }
}

.link--item {
  list-style-type: none;
  text-align: center;
  background: #F7F8F9;
  padding: 15px 15px 10px;
  border-radius: 4px
}

@media (min-width: 860px) {
  .link--item {
    padding-bottom: 15px
  }
}

.link--item.promoblock {
  padding: 25px;
  background: none;
  grid-area: 2 / 1 / span 2 / 1;
  text-align: left;
  justify-content: center;
  display: flex;
  flex-direction: column
}

@media (min-width: 640px) {
  .link--item.promoblock {
    grid-area: 2 / 3 / span 2
  }
}

@media (min-width: 860px) {
  .link--item.promoblock {
    grid-area: 2 / 4 / span 2
  }
}

@media (max-width: 639px) {
  .link--item.promoblock img {
    width: 120%;
    max-width: 120%;
    margin-left: -10%
  }
}

.link--item.promoblock .btn:hover {
  background: rgba(0, 0, 0, 0.05);
  transition: background 0.25s ease-in-out
}

.promoblock-title {
  font-size: 2.75rem;
  font-weight: bold;
  line-height: 100%;
  display: inline-block;
  margin: 20px 0 10px;
  color: #fff
}

@media (min-width: 640px) {
  .promoblock-title {
    font-size: 3rem
  }
}

@media (min-width: 860px) {
  .promoblock-title {
    font-size: 3.3rem
  }
}

.promoblock-paragraph {
  font-size: .938em
}

@media (min-width: 640px) {
  .promoblock-paragraph {
    font-size: 1.1em
  }
}

.link-list--section~.link-list--section .link--item.promoblock {
  grid-area: 1 / 1 / span 1 / span 2
}

@media (min-width: 640px) {
  .link-list--section~.link-list--section .link--item.promoblock {
    grid-area: 2 / 1 / span 2
  }
}

.link-list--section~.link-list--section .link--item.promoblock:last-child {
  grid-area: 4 / 1 / span 1 / span 2
}

@media (min-width: 640px) {
  .link-list--section~.link-list--section .link--item.promoblock:last-child {
    grid-area: 5 / 1 / span 1 / span 3
  }
}

@media (min-width: 860px) {
  .link-list--section~.link-list--section .link--item.promoblock:last-child {
    grid-area: 5 / 1 / span 1 / span 4
  }
}

.link--item-image {
  margin-bottom: 0;
  display: block;
  position: relative;
  height: auto;
  margin: 0;
  aspect-ratio: 1/1
}

.link--item-image img {
  max-width: 100%;
  border-radius: 4px
}

.link--item-info {
  display: inline-block;
  margin-top: 10px;
  color: var(--primary-color);
  font-weight: 600;
  line-height: 130%
}

@media (min-width: 860px) {
  .link--item-info {
    font-size: 1.1em
  }
}

.link-focus--inner {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1400px;
  margin: 0 auto;
  padding: 10px
}

@media (min-width: 860px) {
  .link-focus--inner {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px
  }
}

.link-focus--card {
  display: flex;
  border-radius: 5px;
  margin-bottom: 10px
}

.link-focus--card>div {
  flex: 1 1 0;
  align-self: center;
  max-width: 50%
}

.link-focus--card:nth-child(even) .link-focus--imgcol {
  order: 1
}

.link-focus--imgcol {
  padding: 15px
}

@media (min-width: 860px) {
  .link-focus--imgcol {
    padding: 0
  }
}

.link-focus--textcol {
  font-size: .875em;
  padding: 20px
}

@media (min-width: 860px) {
  .link-focus--textcol {
    padding: 25px;
    font-size: 1em
  }
}

@media (min-width: 1024px) {
  .link-focus--textcol {
    padding: 40px
  }
}

.link-focus--textcol h5 {
  font-size: 1.35rem;
  font-weight: 600
}

@media (min-width: 860px) {
  .link-focus--textcol h5 {
    font-size: 1.5rem
  }
}

@media (min-width: 1024px) {
  .link-focus--textcol h5 {
    font-size: 1.75rem
  }
}

.link-focus--textcol .btn {
  border: 1px solid #000;
  transition: background 0.25s ease-in-out;
  font-size: .875em
}

.link-focus--textcol .btn:hover {
  background: rgba(0, 0, 0, 0.05);
  transition: background 0.25s ease-in-out
}

@media (min-width: 860px) {
  .link-focus--textcol .btn {
    font-size: .938em
  }
}

@media (min-width: 1024px) {
  .link-focus--textcol .btn {
    font-size: 1em
  }
}

.link-banner--container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 10px
}

.link-banner--inner {
  display: flex;
  border-radius: 5px;
  padding: 12px;
  align-items: center
}

.link-banner--inner img {
  max-width: 25vw
}

@media (min-width: 640px) {
  .link-banner--inner img {
    max-height: 50px
  }
}

.link-banner--textwrap {
  padding: 0 20px
}

@media (min-width: 640px) {
  .link-banner--textwrap {
    display: inline-flex;
    justify-content: center;
    flex: 1;
    font-size: 1.15em
  }
}

.link-banner--paragraph {
  margin-bottom: 2px
}

.link-banner--button {
  font-weight: 600;
  text-decoration: underline;
  display: inline-block
}

@media (min-width: 640px) {
  .link-banner--button {
    margin-left: 20px
  }
}

.product--additional {
  display: flex;
  flex-direction: column
}

@media (min-width: 720px) {
  .product--additional {
    display: block
  }
}

.selection-button-wrap {
  margin: 0 0 15px
}

@media (min-width: 720px) {
  .selection-button-wrap {
    margin-bottom: 5px
  }
}

.selection-button-wrap label {
  margin-bottom: 0
}

.selection-button-wrap .option-header {
  margin-bottom: -5px;
  font-size: 1em
}

.selection-button-wrap .option-value-input+.option-value-name {
  background-color: #f2f2f2;
  border-color: transparent;
  color: #000;
  padding: .65rem 1.35rem;
  border-radius: 10px;
  margin-bottom: 8px
}

.selection-button-wrap .option-value-input:checked+.option-value-name {
  background-color: var(--primary-color-2);
  color: #fff
}

@media (min-width: 720px) {
  .selection-button-wrap .option-value-input+.option-value-name {
    font-size: var(--font-size-body)
  }
}

.paint-qty-wrap.paint-qty-wrap {
  opacity: 1;
  position: relative;
  display: flex;
  flex-direction: column
}

.paint-qty-wrap.paint-qty-wrap input {
  border: 0;
  background: #f2f2f2;
  color: #000;
  text-align: center;
  padding: 0.65rem 1.35rem;
  border-radius: 10px
}

.paint-qty-wrap.paint-qty-wrap label {
  font-size: 15px;
  font-weight: 600;
  order: -1;
  color: var(--body-color);
  position: static
}

@media (min-width: 720px) {
  .paint-qty-wrap.paint-qty-wrap label {
    font-size: 14px
  }
}

.paint-qty-ctrl-btn {
  position: absolute;
  top: 35px;
  bottom: 0;
  margin: auto;
  cursor: pointer;
  z-index: 10;
  color: #333;
  font-size: 15px
}

@media (min-width: 720px) {
  .paint-qty-ctrl-btn {
    top: 37px;
    font-size: 12px
  }
}

.paint-qty-ctrl-btn span {
  line-height: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.paint-qty-cb-plus {
  right: 10px
}

.paint-qty-cb-plus span {
  font-size: 1.5em
}

.paint-qty-cb-minus {
  left: 10px
}

.paint-qty-cb-minus span {
  font-size: 1.1em
}

.paint-calculator {
  background: #c6d0d8;
  padding: 15px 18px;
  border-radius: 10px;
  order: 2;
  margin-top: 15px
}

@media (min-width: 720px) {
  .paint-calculator {
    order: initial;
    margin-top: 0
  }
}

.paint-calculator>p {
  font-weight: 400;
  color: #333;
  margin-bottom: 0;
  text-align: center;
  font-size: 1.15em
}

@media (min-width: 720px) {
  .paint-calculator>p {
    font-size: 1em
  }
}

.paint-calculator #paintCalcToggle {
  cursor: pointer;
  transition: color 0.25s ease-in-out;
  display: block;
  font-weight: 600;
  font-size: 1.15em
}

@media (min-width: 720px) {
  .paint-calculator #paintCalcToggle {
    font-size: 1em
  }
}

.paint-calculator #paintCalcToggle:hover {
  color: var(--primary-color);
  transition: color 0.25s ease-in-out
}

.paint-calculator #paintCalcToggle i {
  font-size: .7em
}

.paint-calculator input::-webkit-outer-spin-button,
.paint-calculator input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0
}

.paint-calculator input[type=number] {
  -moz-appearance: textfield;
  height: 46px;
  padding: 0 10px;
  border: 0;
  border-radius: 10px;
  width: 100px
}

#paintCalcWidth {
  margin-right: 8px
}

.paint-calc-form {
  display: none;
  border-top: 1px solid #c7cdd4;
  padding-top: 15px;
  text-align: center
}

@media (min-width: 720px) {
  .paint-calc-form {
    text-align: left
  }
}

.paint-calc-form h6 {
  font-weight: 600;
  color: #333
}

.paint-calc-form>p {
  font-size: .875em
}

.paint-calc-surface-wrap>p {
  color: #333;
  font-weight: 600;
  margin-bottom: 6px
}

.paint-calc-results-wrap {
  display: grid;
  grid-template-columns: 1fr 1.75fr;
  grid-template-rows: 1fr 1fr;
  font-size: .938em;
  text-align: left
}

@media (min-width: 720px) {
  .paint-calc-results-wrap {
    grid-template-columns: 1fr 1.5fr
  }
}

#paintCalcLitresAmount {
  font-size: 1.15em
}

.paint-calc-result-value {
  justify-self: end;
  text-align: right;
  font-weight: bold;
  color: #333
}

.pp-cvd-inner {
  padding: 20px 30px;
  background: #C6D3C1;
  border-radius: 5px;
  margin: 0 -15px;
  color: #797E5E;
  text-align: center;
  font-weight: 500
}

@media (min-width: 768px) {
  .pp-cvd-inner {
    margin: 0
  }
}

.pp-cvd-inner ul {
  padding-left: 15px;
  display: inline-block;
  font-size: .875em
}

.pp-cvd-inner p {
  font-weight: 600;
  margin-bottom: 7px
}

.pp-cvd-inner p strong {
  color: #797E5E;
  font-size: 2em;
  line-height: 110%;
  display: inline-block;
  margin-bottom: 13px
}

.pp-cvd-inner img {
  margin-bottom: 10px;
  max-height: 60px
}

.pp-cvd-inner .product-customiser-jump-button.product-customiser-jump-button {
  background: #797E5E;
  color: #fff;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 15px;
  border: 0
}

.pp-cvd-inner .product-customiser-jump-button.product-customiser-jump-button:hover {
  background-color: #fff;
  text-decoration: none;
  border: 0;
  color: #797E5E
}

.option-wrapper {
  z-index: 10;
  margin-bottom: 20px
}

.option-wrapper .option {
  text-align: center;
  margin: 0;
  padding: 15px;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  transition: all .2s ease;
  position: relative;
  display: flex;
  flex-direction: column
}

.option-wrapper .option .option-label {
  position: static;
  display: block
}

.option-wrapper .option .card-img {
  color: #f3f3f3;
  height: 100%;
  max-height: 100%
}

.option-wrapper .option .form-check__view {
  display: inline-block;
  background-color: #F3F4F8;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid white;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(25%, 25%);
  transition: all .2s
}

.option-wrapper .option .form-check__view:hover {
  width: 32px;
  height: 32px
}

.option-wrapper .option:hover {
  -moz-box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px
}

.option-wrapper input[type="radio"]:checked~.option,
.option-wrapper input[type="checkbox"]:checked~.option {
  border-color: var(--primary-color);
  -moz-box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px
}

.option-wrapper input[type="radio"]:checked~.option .form-check__indicator,
.option-wrapper input[type="checkbox"]:checked~.option .form-check__indicator {
  display: initial;
  transform: translate(35%, 35%);
  background-color: white;
  border-radius: 50%;
  left: 0;
  top: 0;
  z-index: 1
}

.choice-group {
  border-radius: 5px;
  padding: 15px;
  background-color: white;
  position: relative;
  border: 1px solid rgba(127, 127, 127, 0.16);
  box-shadow: 0 1px 4px rgba(127, 127, 127, 0.11)
}

.choice-group::before {
  border: 1px solid rgba(127, 127, 127, 0.16);
  box-shadow: 0 1px 4px rgba(127, 127, 127, 0.11);
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: white;
  bottom: 0;
  left: 45px;
  transform: translateY(50%) rotate(45deg)
}

.choice-group::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  top: 0;
  left: 0;
  border-radius: inherit
}

.choice-group .option {
  padding: 0;
  width: 60px;
  height: 60px;
  border-radius: 5px
}

.choice-group .row {
  flex-wrap: nowrap;
  overflow-y: auto
}

.choice-group .example::-webkit-scrollbar {
  display: none
}

.choice-group .example {
  -ms-overflow-style: none;
  scrollbar-width: none
}

.cart-item--content-title {
  font-weight: 600
}

@media (max-width: 767px) {
  .cart-item--content-title {
    max-width: 90%
  }
}

.cart-item--remove {
  position: absolute;
  right: 10px;
  top: 0
}

.cart-item h3 {
  font-size: 1em
}