@use '@styles/settings/_variables.scss' as var;

.pd-navigation {
  height: calc(100vh - 44px);
  background: black;
  color: var.$color-white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 0 0 20px 20px;
  overflow: hidden;

  .pd-navigation-main-picture {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 150%;
    z-index: 1;
    bottom: 2%;
    border-radius: 0 0 20px 20px;
  }

  p {
    font-family: 'Poppins', sans-serif !important;
  }

  .navigation-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    z-index: 10;

    .navigation-text {
      letter-spacing: 0.05em;
      text-align: center;
      justify-content: center;
      margin-top: 20px;

      .navigation-text-welcome {
        font-size: 16px;
        font-weight: 300;
        padding: 0 20px;
      }

      .navigation-text-description {
        font-size: 32px;
        font-weight: 500;
        margin-top: 24px;
      }
    }

    .navigation-text-desktop {
      display: none;
    }

    .navigation-button-group {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .pd-button {
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
  }

  @media (min-width: 750px) and (max-width: 1023px) {
    .pd-navigation-main-picture {
      width: 120%;
    }
  }

  @media screen and (min-width: 1024px) {
    height: calc(100vh - 64px);
    .pd-navigation-main-picture {
      width: 80%;
      z-index: 1;
      left: 75%;
      bottom: 4%;
      border-radius: 0 0 20px 20px;
    }

    .navigation-container {
      gap: 64px;

      .navigation-text {
        display: none;
      }

      .navigation-text-desktop {
        display: block;
        letter-spacing: 0.05em;
        text-align: left;
        max-width: 560px;

        .navigation-text-welcome {
          font-size: 20px;
          font-weight: 300;
        }

        .navigation-text-description {
          font-size: 42px;
          font-weight: 500;
          letter-spacing: 0.05em;
          margin-top: 24px;
        }
      }

      .navigation-button-group {
        flex-direction: row;
        gap: 12px;

        .pd-button {
          padding: 20px;
        }
      }
    }
  }
}

.pd-navigation-general-text-page {
  height: 403px;
  background-image: url('../../../assets/images/header-and-nav-page/nav-general-text-page-mobile.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: var.$color-white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 0 0 20px 20px;

  .pd-navigation-general-text-page-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #2f30375c;
    border-radius: 0 0 20px 20px;
  }

  @media screen and (min-width: 1024px) {
    background-image: url('../../../assets/images/header-and-nav-page/nav-general-text-page-desktop.webp');
    height: 530px;
  }

  &.faq .navigation-general-text-page-container {
    transform: translateY(25%);
  }

  .navigation-general-text-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    letter-spacing: 0.05em;
    transform: translateY(50%);
    text-align: center;

    

    .navigation-general-text-page-text-title {
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      letter-spacing: 0.05em;
    }

    .navigation-general-text-page-text-subtitle {
      font-family: var.$font-poppins;
      font-size: 22px;
      letter-spacing: 0.05em;
      font-weight: 500;
    }

    @media screen and (min-width: 1024px) {
      align-items: start;
      text-align: start;
      transform: translateY(60%);

      .navigation-general-text-page-text-title {
        font-size: 20px;
        line-height: 30px;
        max-width: 580px;
      }

      .navigation-general-text-page-text-subtitle {
        font-size: 42px;
        font-weight: 500;
        line-height: 63px;

        &.contact-us {
          font-size: 32px;
          font-style: normal;
          line-height: normal;
          letter-spacing: 1.6px;
        }
      }
    }
  }
}

.pd-about-us-navigation {
  background-color: var.$color-light-blue-2;

  .pd-navigation-general-text-page {
    background-image: url('../../../assets/images/header-and-nav-page/nav-about-us-mobile.webp');
    background-size: cover;
    background-position: center;

    @media screen and (min-width: 1024px) {
      background-image: url('../../../assets/images/header-and-nav-page/nav-about-us-desktop.webp');
    }
  }
}

.pd-navigation-background {
  background-color: var.$color-light-blue-2;
}

.pd-service-navigation {
  background-color: var.$color-light-blue-2;

  .pd-navigation-general-text-page {
    background-image: url('../../../assets/images/header-and-nav-page/nav-service-mobile.webp');
    background-size: cover;
    background-position: center;

    .navigation-general-text-page-container {
      transform: translateY(30%);
    }

    @media screen and (min-width: 1024px) {
      background-image: url('../../../assets/images/header-and-nav-page/nav-service-desktop.webp');

      .navigation-general-text-page-container {
        transform: translateY(30%);
        .navigation-general-text-page-text-subtitle {
          max-width: 560px;
        }
      }
    }
  }
}

.pd-contact-navigation {
  .pd-navigation-general-text-page {
    background-image: url('../../../assets/images/header-and-nav-page/contact_bg_resize_compress.webp');
    background-size: cover;
    background-position: 0px 0px;

    .navigation-general-text-page-container {
      transform: translateY(30%);

      &.contact-us {
        transform: translateY(25%);
      }
    }

    .navigation-general-text-page-text-subtitle {
      &.contact-us {
        max-width: 400px;
      }
    }


    @media screen and (min-width: 1024px) {
      background-image: url('../../../assets/images/header-and-nav-page/contact_bg_resize_compress.webp');

      .navigation-general-text-page-container {
        transform: translateY(30%);
        .navigation-general-text-page-text-subtitle {
          max-width: 560px;

          &.contact-us {
            max-width: 778px;
          }
        }
      }
    }
  }
}
