@use '@styles/settings/_variables.scss' as var;

@keyframes scrollLogos {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(-100%, 0, 0);
  }
}

.pd-accreditation-container {
  padding: 64px 0 72px 0;

  .pd-accreditation-title {
    text-align: center;
  }

  .pd-accreditation-logo-items {
    display: flex;
    gap: 52px;
    justify-content: center;
    overflow: hidden;

    .pd-accreditation-logos {
      animation: scrollLogos 20s infinite linear;
      display: flex;
      align-items: center;
      gap: 52px;
      margin-top: 32px;

      img {
        height: 48px;
        width: auto;
      }

      .pd-accreditation-hicap {
        height: 30px;
        width: auto;
      }

      .pd-accreditation-ais {
        height: 40px;
        width: auto;
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media screen and (min-width:1024px) {
    padding: 80px 0;

    .pd-accreditation-logo-items {

      .pd-accreditation-logos {
        animation: none;
        gap: 112px;
      }

      .pd-accreditation-logos-repeated {
        display: none;
      }
    }
  }
}