@use '@styles/settings/_variables.scss' as var;

.pd-skeleton {
  display: block;
  width: 200px;
  height: 16px;
  background-color: #D8DDE1;

  &.image {
    height: unset;
    padding-top: 100%;
  }

  &.dark {
    background-color: var.$color-primary;
  }
}

.pd-skeleton-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
}

.pd-skeleton-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5%;
  width: 100%;
}


.pd-vanity-gallery-main__image.pd-skeleton {
  width: 100%;
  height: unset;

  + .pd-skeleton-column {
    margin-top: 30px;
  }
}

.pd-vanity-gallery-thumbnails {
  .pd-skeleton.image {
    width: 67px;
    @media screen and (max-width: var.$breakpoint-phone-max) {
      width: 50px;
      height: 50px;
      padding: 0;
    }
  }

  + .pd-skeleton-column {
    margin-top: 20px;
  }
}