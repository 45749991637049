@use '@styles/settings/_variables.scss' as var;

.pd-drawer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  z-index: 9999;
  transition: .5s;

  &:not(.is-open) {
    z-index: -999;

    &::before {
      opacity: 0;
    }

    .pd-drawer-container {
      transform: translateX(-100%);
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(53, 78, 102, .5);
    opacity: 1;
    transition: opacity .5s;
  }

  &-container {
    position: relative;
    background-color: white;
    width: 716px;
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    transform: translateX(0);
    //transition: transform .5s;

    @media screen and (min-width: 1024px) {
      max-width: 95%;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 40px;
    border-bottom: 1px solid var.$color-secondary;

    h2 {
      margin: 0;
      color: var.$color-base;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1px;
      line-height: 1.5;
      text-transform: uppercase;
    }
  }

  &-close-button {
    background: none;
    border: 0;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:focus,
    &:focus-visible {
      outline: none;
    }
  }

  &-message {
    background-color: var.$color-body-bg;
    padding: 8px 20px;
    color: black;
    font-size: 12px;
    letter-spacing: .72px;
    text-align: center;
    text-transform: uppercase;
  }

  &-body {
    display: flex;
    max-height: calc(100% - 181px);
    padding: 0 20px 0 40px;
    flex-shrink: 0;
    overflow: hidden;
  }

  &-body-inner {
    max-height: 100%;
    overflow-y: auto;
    padding: 40px 20px 40px 0;
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 18px;
    height: 108px;
    padding: 16px 40px;
    border-top: 1px solid var.$color-secondary;
  }
}