@use '@styles/settings/_variables.scss' as var;

.pd-choosen-vanity-full-width {
  position: relative;
  z-index: 2;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  @media screen and (max-width: var.$breakpoint-phone-max) {
    display: none;
  }

  .page-width {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .pd-choosen-vanity-item {
    box-shadow: none;
    align-items: center;
    padding-left: 0;

    &__image {
      min-width: 88px;
      max-width: 88px;
    }

    &__info {
      p {
        margin-bottom: 0;
      }
    }

    &__button {
      margin-left: 30px;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 12px;
    
    .pd-button {
      min-width: 189px;
    }
  }
}
