@use '@styles/settings/_variables.scss' as var;

.pd-general-text-page-content {
  .pd-general-text-page-content-container {
    display: flex;
    flex-direction: column;
    gap: 48px;
    padding-top: 56px;
    padding-bottom: 64px;
    
    @media screen and (min-width: 1024px) {
      padding-top: 64px;
      padding-bottom: 64px;
    }

    .pd-general-text-page-item {
      display: flex;
      flex-direction: column;
      gap: 16px;
      text-align: left;

      .general-text-page-item-title {
        color: var.$color-black-1;
        font-size: 22px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
      }

      .general-text-page-item-description, .general-text-page-item-details {
        color: var.$color-gray-2;
        font-family: var.$font-figtree;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.005em;
      }

      .general-text-page-item-link {
        color: var.$color-electric-blue;
        text-decoration: underline;
      }
    }
  }
}