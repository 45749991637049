@use '@styles/settings/_variables.scss' as var;

.pd-header {
  font-family: "Figtree", sans-serif;
  position: absolute;
  height: fit-content;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;

  .pd-header-container {
    background-color: #FFFFFF1A;
    padding: 10px 20px;
    border-radius: 10px;
    backdrop-filter: blur(5px);

    .header-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .pd-header-logo-mobile {
        height: 42.4px;
      }

      .header-hamburger-menu-toggle {
        display: flex;
        flex-direction: column;
        align-items: center;
        -webkit-user-select: none;
        user-select: none;
        background-color: var.$color-electric-blue;
        padding: 8px;
        border-radius: 8px;

        #header-checkbox {
          display: flex;
          position: absolute;
          cursor: pointer;
          opacity: 0;
          z-index: 2;
        }

        span {
          display: flex;
          width: 20px;
          height: 2px;
          margin-bottom: 2.5px;
          margin-top: 2.5px;
          position: relative;
          background: #ffffff;
          border-radius: 3px;
          z-index: 1;
          transform-origin: 5px 0px;
          transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
          background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
          opacity 0.55s ease;
        }

        span:first-child {
          transform-origin: 0% 0%;
        }

        span:nth-last-child(2) {
          transform-origin: 0% 100%;
        }

        #header-checkbox:checked ~ span {
          opacity: 1;
          transform: rotate(45deg) translate(5.5px, 1px);
          background: var.$color-white;
        }

        #header-checkbox:checked ~ span:nth-last-child(3) {
          opacity: 0;
          transform: rotate(0deg) scale(0.2, 0.2);
        }

        #header-checkbox:checked ~ span:nth-last-child(2) {
          transform: rotate(-45deg) translate(3px, 3px);
        }
      }

      .header-menu-mobile-items {
        position: absolute;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        top: 110px;
        -webkit-font-smoothing: antialiased;
        transform-origin: 0% 0%;
        transition: transform 2s cubic-bezier(0.77, 0.2, 0.05, 1.0);
        height: calc(100vh - 200px);
        opacity: 0;
        display: none;

        .header-menu-mobile-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          background-color: white;
          padding: 10px 0 71px 0;
          border-radius: 10px;
          height: 100%;
        }

        .header-menu-mobile-items-ul {
          display: flex;
          flex-direction: column;
          gap: 8px;
          list-style-type: none;
          color: var.$color-gray;
          font-size: 15px;

          .header-menu-phone-number {
            padding: 12px;
            display: flex;
            gap: 16px;
          }

          .header-menu-social-contact {
            padding: 12px;
            display: flex;
            gap: 16px;
          }
        }
      }

      .header-menu-mobile-items li {
        padding: 16px 0;
        transition-delay: 2s;

        a {
          font-family: var.$font-poppins;
          color: var.$color-dark-blue-2;
          text-decoration: none;
          font-size: 15px;
          font-weight: 400;
          letter-spacing: 0.005em;
          text-align: left;
        }
      }

      .header-hamburger-menu-toggle #header-checkbox:checked ~ .header-menu-mobile-items {
        opacity: 1;
        display: block;
      }
    }

  }

  .header-menu-desktop {
    display: none;
  }

  @media screen and (min-width: 1024px) {
    .pd-header-container {
      display: none;
    }

    .header-menu-desktop {
      backdrop-filter: blur(5px);
      background-color: #FFFFFF1A;
      padding: 10px 60px;
      display: flex;
      justify-content: space-between;
      border-radius: 10px;

      .header-menu-desktop-items {
        display: flex;
        gap: 55px;
        align-items: center;

        .header-menu-desktop-links {
          display: flex;
          gap: 46px;
        }
      }

      .header-button-group {
        display: flex;
        align-items: center;
      }
    }
  }
}

#lbl-header-checkbox {
  height: 0;
  margin-bottom: 0;
  width: 0;
  visibility: hidden;
}