@use '@styles/settings/_variables.scss' as var;

.pd-vanity-gallery {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.pd-vanity-gallery-content {
  display: flex;
  gap: 10px;
  margin-top: 32px;
  @media screen and (max-width: var.$breakpoint-phone-max) {
    position: relative;
    flex-direction: column;
    padding-bottom: 55px;
  }

  &.is-zoom-in {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    margin: 0;
    padding: 40px;
    z-index: 9999;

    .pd-vanity-gallery-main__buttons {
      display: none;
    }

    .pd-vanity-gallery-main__button-zoomer {
      .icon-close {
        display: block;
      }

      .icon-zoomer {
        display: none;
      }
    }
  }
}

.pd-vanity-gallery-main {
  position: relative;
  flex-grow: 1;
  overflow: hidden;
  @media screen and (max-width: var.$breakpoint-phone-max) {
    position: unset;
  }

  &__image {
    position: relative;
    width: 100%;

    &::before {
      content: '';
      display: inline-block;
      width: 100%;
      padding-top: 72%;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      max-width: 100%;
    }
  }

  &__button-zoomer {
    position: absolute;
    bottom: 16px;
    right: 16px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 0;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:focus,
    &:focus-visible {
      outline: none;
    }

    .icon-close {
      display: none;
    }
  }

  &__buttons {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    @media screen and (max-width: var.$breakpoint-phone-max) {
      padding: 0;
      gap: 8px;
      width: 100%;

      .pd-button {
        flex-grow: 1;
        padding: 7px 12px;
      }
    }
  }

  .pd-button:hover {
    color: white;
  }
}

.pd-vanity-gallery-thumbnails {
  min-width: 67px;
  max-width: 67px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media screen and (max-width: var.$breakpoint-phone-max) {
    flex-direction: row;
    gap: 8px;
    max-width: 100%;
    overflow-x: auto;
  }
}

.pd-vanity-gallery-thumbnail {
  position: relative;
  overflow: hidden;
  width: 67px;
  height: 67px;
  cursor: pointer;
  @media screen and (max-width: var.$breakpoint-phone-max) {
    min-width: 51px;
    max-width: 51px;
    height: 51px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.is-active {
      border: 1px solid var.$color-base;
    }
  }
}