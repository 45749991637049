@use '@styles/settings/_variables.scss' as var;

.announcement-bar {
  font-family: var.$font-poppins;
  color: var.$color-dark-blue;
  background: var.$color-white;
  font-size: 14px;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;

  .first-region {
    display: none;

    .announcement-icon-link-groups {
      .announcement-icon {
        display: none;
      }
    }
  }

  .second-region {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-right: 0;
    max-width: 100%;

    @media screen and (min-width: 768px) {
      max-width: 50%;
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
      display: none;
    }

    .swiper-button-next {
      right: 0;
    }

    .swiper-button-prev {
      left: 0;
    }

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 768px) {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        text-align: center;
      }
    }

    .announcement-icon {
      display: flex;
      align-items: center;

      svg path {
        fill: #d6d6e6;
      }
    }

    .second-region-text {
      font-family: var.$font-poppins;
      font-weight: 500;
      text-align: center;
    }
  }

  @media (min-width: 1024px) {
    justify-content: space-between;
    font-size: 16px;
    padding-top: 20px;
    padding-bottom: 20px;

    .first-region {
      display: flex;
      gap: 24px;
      min-width: max-content;

      .announcement-icon-link-groups {
        display: flex;
        align-items: center;
        gap: 16px;

        .announcement-contact-link {
          display: flex;
          gap: 16px;
          align-items: center;
          &:hover {
            color: var.$color-electric-blue !important;
            .announcement-icon {
              color: var.$color-electric-blue !important;
            }
          }

          .announcement-icon {
            color: var.$color-light-blue !important;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}
