@use '@styles/settings/_variables.scss' as var;

@keyframes waving-1 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes waving-2 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes waves-up {
  0% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(-250px);
  }
}

@keyframes text-fading {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  10%{
    opacity: 0;
  }
  20% {
    opacity: 1;
    transform: translateY(-50px);
  }
  30% {
    opacity: 0;
  }
  40% {
    opacity: 1;
    transform: translateY(-100px);
  }
  50% {
    opacity: 0;
  }
  60% {
    opacity: 1;
    transform: translateY(-150px);
  }
  70% {
    opacity: 0;
  }
  80% {
    opacity: 1;
    transform: translateY(-200px);
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(-250px);
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.pd-wave-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  width: 500px;
  background-color: white;
  @media screen and (max-width: var.$breakpoint-phone-max) {
    transform: translate(-50%, -50%) scale(.4);
  }
  
  &::before {
    content: '';
    display: inline-block;
    padding-top: 100%;
  }

  &-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    overflow: hidden;
    width: 80%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .__waves {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(50%);
    width: 100%;
    height: 100%;
  }

  .__wave-1 {
    position: absolute;
    top: 0;
    left: -13%;
    transform: rotate(180deg);
    width: 2000px;
    height: 1000px;
    background-color: #6989a3;
    animation-name: waving-1;
    animation-duration: 6s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  .__wave-2 {
    position: absolute;
    top: 0;
    right: -10%;
    width: 2000px;
    height: 1000px;
    animation-name: waving-2;
    animation-duration: 5s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  &.is-ready {
    .__waves {
      animation-name: waves-up;
      animation-duration: 2s;
      animation-fill-mode: forwards;
      animation-timing-function: linear;
    }

    .__texts.animated {
      animation-name: fade-out;
      animation-delay: 3s;
      animation-duration: 3s;
      animation-fill-mode: forwards;
    }

    .__texts.ready {
      animation-name: fade-in;
      animation-delay: 4s;
      animation-duration: 3s;
      animation-fill-mode: forwards;
    }
  }

  .__overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .2));
    opacity: .2;
  }

  .__texts {
    position: absolute;
    top: calc(50% - 25px);
    left: 0;
    color: #C5D0D8;
    font-size: 16px;
    font-weight: 700;
    z-index: 2;
    text-align: center;
    width: 100%;
    height: 50px;
    overflow: hidden;
    @media screen and (max-width: var.$breakpoint-phone-max) {
      font-size: 25px;
    }

    &.ready {
      opacity: 0;
      transform: translateY(50px);
    }

    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      animation-name: text-fading;
      animation-duration: 10s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      opacity: 0;
    }
  }

  .__text {
    height: 50px;
    padding: 12px 0;
  }
}

.pd-wave {
  --mask: radial-gradient(193.24px at 50% 261.00px,#000 99%,#0000 101%) calc(50% - 180px) 0/360px 100%,
    radial-gradient(193.24px at 50% -171px,#0000 99%,#000 101%) 50% 90px/360px 100% repeat-x;
  -webkit-mask: var(--mask);
  mask: var(--mask);
  background: #354E66;
  height: 250px;
}