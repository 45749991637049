@use '@styles/settings/_variables.scss' as var;

.pd-page-heading {
  background-color: var.$color-secondary;
  padding: 40px 0;

  h1 {
    margin: 0;
    font-size: 36px;
    font-weight: 400;
    @media screen and (max-width: var.$breakpoint-phone-max) {
      font-size: 30px;
    }
  }
}