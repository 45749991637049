@use '@styles/settings/_variables.scss' as var;

.pd-note-panel {
  margin: 24px 0;
  padding: 14px;
  background-color: var.$color-body-bg;

  &__content {
    max-width: 833px;
    margin: 0 auto;
    color: #6989A3;
    font-size: 14px;
    line-height: 1.5;

    > p:last-child {
      margin-bottom: 0;
    }
  }
}