@use '@styles/settings/_variables.scss' as var;

.pd-uvp-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .pd-uvp-title {
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0.05em;
  }

  .pd-uvp-subtitle {
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0.03em;
  }

  @media screen and (min-width: 1024px) {
    align-items: start;
    text-align: start;

    .pd-uvp-title {
      font-size: 20px;
      font-weight: 300;
      line-height: 30px;
      letter-spacing: 0.05em;
    }

    .pd-uvp-subtitle {
      font-size: 32px;
      line-height: 48px;
    }
  }
}

.pd-uvp-content-item {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 240px;
  max-width: 320px;

  img {
    width: 48px;
    height: 48px;
  }

  .pd-uvp-content-item-text {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .pd-uvp-content-item-title {
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      letter-spacing: 0em;
    }

    .pd-uvp-content-item-description {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: var.$color-gray;
    }
  }

  .pd-uvp-item-button {
    border: 0;
    background: #fff;
    color: #000596;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-align: left;
    text-transform: uppercase;

    margin-top: auto;
    padding: 0;
    cursor: pointer;

    &:active,
    &:focus {
      box-shadow: none;
      border: none;
      outline: none;
    }
  }
}

.pd-uvp-mobile {
  display: flex;
  flex-direction: column;
  gap: 48px;

  .pd-uvp-heading {
    text-align: left;
    align-items: flex-start;
  }
  .pd-uvp-title {
    font-size: 16px;
  }
  .pd-uvp-mobile-image {
    width: 100%;
    height: fit-content;
    min-height: 208px;
    overflow: hidden;
    object-fit: cover;
    display: flex;
    align-items: end;
    justify-content: end;

    img {
      width: 100%;
      border-radius: 10px;
      height: auto;
    }
  }

  .pd-uvp-content {
    display: flex;
    gap: 32px;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 48px;
    margin-bottom: 48px;

    &::-webkit-scrollbar {
      height: 2px;
      width: 40%;
      background-color: var.$color-light-blue;
      top: 100px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px var.$color-light-blue;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var.$color-electric-blue;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: var.$color-electric-blue;
    }

    @-moz-document url-prefix() {
      & {
        /* Firefox-specific adjustments */
        scrollbar-width: thin; /* Makes the scrollbar thinner */
        scrollbar-color: var.$color-electric-blue var.$color-light-blue; /* Colors the thumb and track */
      }
    }
  }

  .pd-uvp-content01 {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .pd-uvp-content01-description {
      text-align: center;
    }

    .pd-uvp-content01-cta {
      margin: 48px 0;
    }
  }

  @media screen and (min-width: 1024px) {
    display: none;
  }
}

.pd-uvp-desktop {
  display: none;

  @media screen and (min-width: 1024px) {
    display: flex;

    .pd-uvp-desktop-image {
      width: 42%;
      overflow: hidden;
      object-fit: cover;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        object-fit: cover;
        width: 100%;
        height: auto;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
      }
    }

    .pd-uvp-00-desktop-image,
    .pd-uvp-01-desktop-image {
      width: 50%;
      overflow: hidden;
      object-fit: cover;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-height: 780px;
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px;
      }
    }

    .pd-uvp-00-desktop-image {
      img {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: 20px;
        border-top-right-radius: 20px;
      }
    }

    .pd-uvp-desktop-text {
      padding: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 64px;
      width: 50%;
      max-width: 520px;
      box-sizing: content-box;

      .pd-uvp-content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 32px;
      }

      .pd-uvp-content01 {
        display: flex;
        flex-direction: column;
        gap: 64px;
        align-items: start;

        .pd-uvp-content01-description {
          font-size: 18px;
        }
      }
    }
  }
}

.pd-uvp-why-choose-us {
  background-color: var.$color-light-blue-2;
  padding-top: 64px;
  padding-bottom: 64px;

  .pd-uvp-why-choose-us-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;

    .pd-uvp-why-choose-us-title {
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
      letter-spacing: 0.05em;
      text-align: center;
    }
    .pd-uvp-why-choose-us-body {
      max-width: 767px;
      font-family: Poppins;
      font-size: 18px;
      font-weight: 300;
      line-height: 27px;
      text-align: center;
      margin-bottom: 20px;

      .text-blue {
        margin-top: 24px;
        color: var.$color-electric-blue;
      }
    }

    .pd-uvp-why-choose-us-columns {
      display: grid;
      gap: 48px;

      .pd-uvp-why-choose-us-column {
        text-align: center;
        letter-spacing: 0em;
        display: flex;
        flex-direction: column;
        align-items: center;

        .pd-uvp-why-choose-us-column-title {
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          margin-bottom: 8px;
        }

        .pd-uvp-why-choose-us-column-description {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: var.$color-gray;
        }

        .pd-uvp-why-choose-us-btn {
          font-family: var.$font-poppins;
          cursor: pointer;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.1em;
          text-align: left;
          background: transparent;
          border: 0;
          outline: none;
          text-transform: uppercase;
          color: var.$color-electric-blue;
          margin-top: auto;

          &:focus {
            box-shadow: none;
            border: 0;
            outline: none;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    padding-top: 88px;
    padding-bottom: 0;

    .pd-uvp-why-choose-us-container {
      gap: 80px;

      .pd-uvp-why-choose-us-title {
        font-size: 32px;
        line-height: 48px;
      }

      .pd-uvp-why-choose-us-body {
        margin-top: -32px;
        margin-bottom: 20px;
      }

      .pd-uvp-why-choose-us-columns {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}

.pd-uvp-about-us {
  background-color: var.$color-white;
  border-radius: 20px;

  &.page-width {
    @media screen and (max-width: 767px) {
      padding: 0;
    }
  }

  .pd-uvp-content {
    margin-bottom: 48px;
  }

  .pd-uvp-mobile .pd-uvp-mobile-image {
    min-height: unset;

    img {
      border-radius: 10px 10px 0 0;
    }
  }

  @media screen and (min-width: 1024px) {
    padding: 0;

    .pd-uvp-content {
      margin-bottom: 0;
    }

    .pd-uvp-desktop {
      flex-direction: column;
      padding-top: 0;

      .pd-uvp-desktop-image {
        width: 100%;

        img {
          border-radius: 20px 20px 0px 0px;
        }
      }

      .pd-uvp-desktop-text {
        margin: 0;
        margin-top: 88px;
        flex-direction: row;
        max-width: 100%;
        width: 100%;
        box-sizing: border-box;
        padding-top: 0;
        padding-bottom: 0;
        gap: 120px;
        padding-bottom: 88px;
      }

      .pd-uvp-heading {
        max-width: 420px;
      }

      .pd-uvp-title {
        font-family: Poppins;
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: 0.05em;
      }
      .pd-uvp-subtitle {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 300;
        line-height: 27px;
        margin-top: 16px;
      }

      .pd-uvp-desktop-image {
        img {
          height: 100%;
          max-height: 520px;
        }
      }
    }
  }
}

.pd-uvp-service {
  background-color: var.$color-light-blue-2;
  border-radius: 20px;
  .pd-uvp-mobile-service {
    @media screen and (min-width: 1024px) {
      display: none;
    }

    .pd-uvp-mobile-service-container {
      display: flex;
      flex-direction: column;
      gap: 48px;
      background-color: var.$color-white;
      border-radius: 20px 20px 0 0;
      padding: 48px 20px;
      align-items: center;

      .pd-uvp-heading-service {
        text-align: center;
        .pd-uvp-title {
          font-size: 24px;
          font-weight: 500;
          line-height: 36px;
          letter-spacing: 0.05em;
          padding-bottom: 32px;
        }
        .pd-uvp-description {
          font-size: 20px;
          font-weight: 300;
          line-height: 30px;
        }
      }
    }

    .pd-uvp-mobile-image {
      @media screen and (min-width: 1024px) {
        display: none;
      }

      width: 100%;
      overflow: hidden;
      display: flex;
      align-items: start;
      justify-content: center;
      height: auto;
      border-radius: 0 0 20px 20px;
      min-height: 393px;
      max-height: 650px;
      img {
        height: auto;
        width: 100%;
        object-fit: cover;
        border-radius: 0 0 20px 20px;
      }
    }
  }

  .pd-uvp-desktop-service {
    display: none;

    @media screen and (min-width: 1024px) {
      display: flex;
      align-items: center;

      .pd-uvp-service-desktop-image {
        max-width: 603px;
        border-radius: 20px 0 0 20px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 780px;

        .img {
          height: 780px;
          width: 100%;
          object-fit: cover;
          border-radius: 20px 0 0 20px;
          max-height: 780px;
        }
      }

      .pd-uvp-desktop-service-text {
        width: 60%;
        padding: 154px 124px;
        max-height: 780px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: var.$color-white;
        border-radius: 0 20px 20px 0;

        .pd-uvp-heading-service {
          letter-spacing: 0.05em;
          text-align: left;

          .pd-uvp-title {
            font-size: 32px;
            font-weight: 500;
            line-height: 48px;
            padding-bottom: 32px;
          }

          .pd-uvp-description {
            font-size: 20px;
            font-weight: 300;
            line-height: 30px;
          }
        }

        .pd-uvp-content-service {
          margin-top: 64px;
        }
      }
    }
  }
}

.uvp-what-make-us-different {
  background-color: var.$color-light-blue-2;
  padding-top: 0;
  padding-bottom: 64px;

  .uvp-what-make-us-different-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    letter-spacing: 0.05em;
    text-align: center;

    .uvp-what-make-us-different-title {
      font-size: 24px;
      font-weight: 500;
    }

    .uvp-what-make-us-different-description {
      font-size: 20px;
      font-weight: 300;
    }
  }

  @media screen and (min-width: 1024px) {
    padding-bottom: 0;

    .uvp-what-make-us-different-container {
      gap: 18px;

      .uvp-what-make-us-different-title {
        font-size: 32px;
      }
    }
  }
}

.pd-uvp-read-more {
  font-family: var.$font-poppins;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-align: left;
  background: transparent;
  border: 0;
  outline: none;
  text-transform: uppercase;
  color: var.$color-electric-blue;
  margin-top: 10px;
  padding: 0;

  &:focus {
    box-shadow: none;
    border: 0;
    outline: none;
  }
}

.pd-uvp {
  &.contact-us {
    .pd-uvp-desktop-text {
      gap: 48px;

      @media screen and (min-width: 1024px) {
        gap: 32px;
      }
    }

    .pd-uvp-heading {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;

      @media screen and (min-width: 1024px) {
        align-items: flex-start;
        gap: 8px;
      }
    }

    .pd-uvp-title {
      color: var.$color-black;
      text-align: center;
      font-family: var.$font-poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 1px;

      @media screen and (min-width: 1024px) {
        color: var.$color-dark-blue;
        font-size: 18px;
        line-height: 1.5;
        letter-spacing: 0.09px;
      }
    }

    .pd-uvp-subtitle {
      color: var.$color-black;
      font-family: var.$font-poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: 0.8px;
      text-align: center;

      @media screen and (min-width: 1024px) {
        text-align: start;
        color: var.$color-gray-3;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0.08px;
      }
    }
  }
}

.pd-uvp-contact-us {
  display: flex;
  flex-direction: column;
  gap: 48px;

  @media screen and (min-width: 1024px) {
    gap: 32px;
  }

  &__block {
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    text-align: start;
    border-top: 1px solid var.$color-off-white;
  }

  &__heading {
    color: var.$color-dark-blue;
    font-family: var.$font-poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.09px;
  }

  .grid-2 {
    display: grid;
    gap: 24px;

    @media screen and (min-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__text {
    color: var.$color-gray-3;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.08px;
  }
}