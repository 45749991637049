@use '@styles/settings/_variables.scss' as var;

.pd-faq {
  padding: 0 !important;
  gap: 0 !important;

  .pd-faq-menu-mobile {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 24px;
    padding-bottom: 24px;
    margin-bottom: 60px;
    position: sticky;
    top: 0;
    left: 0;
    background-color: var.$color-white;

    .pd-faq-menu-mobile-jump-to {
      font-family: var.$font-figtree;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: center;
      color: var.$color-gray-3;
    }

    @media screen and (min-width: 1024px) {
      display: none;
    }
  }

  .pd-faq-menu-desktop {
    display: none;

    @media screen and (min-width: 1024px) {
      display: flex;
      align-items: center;
      gap: 48px;
      padding: 40px 0;
      letter-spacing: 0em;
      position: sticky;
      top: 0;
      left: 0;
      background-color: var.$color-white;

      .pd-faq-menu-desktop-jump-to {
        font-family: var.$font-figtree;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        text-align: center;
        color: #6C7475;
      }

      .pd-faq-menu-desktop-menu {
        position: relative;
        overflow-x: auto;
        overflow-y: hidden;
        height: 45px;
        flex: 1;

        .pd-faq-menu-desktop-icon {
          color: var.$color-electric-blue;
          z-index: 1000;
          height: 45px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .pd-faq-menu-desktop-icon-left {
          position: absolute;
          left: 0;
          padding-right: 100px;
          background: linear-gradient(90deg, #FFFFFF 66.94%, rgba(255, 255, 255, 0) 100%);

          @keyframes chevronLeftClick {
            0% {
              color: var.$color-white;
            }
            60% {
              padding: 5px 3px 5px 7px;
              border: 1px solid var.$color-electric-blue;
            }
            70% {
              color: var.$color-white;
              background-color: var.$color-electric-blue;
            }
            85% {
              padding: 5px 6px 5px 4px;
            }
            100% {
              padding: 5px;
              border: 1px solid var.$color-electric-blue;
              color: var.$color-electric-blue;
              background-color: var.$color-white;
            }
          }

          &-clicked {
            .pd-faq-menu-desktop-chevron {
              animation: chevronLeftClick ease-out 400ms;
            }
          }
        }

        .pd-faq-menu-desktop-icon-right {
          position: absolute;
          right: 0;
          padding-left: 100px;
          background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 66.94%);

          @keyframes chevronRightClick {
            0% {
              color: var.$color-white;
            }
            60% {
              padding: 5px 7px 5px 3px;
              border: 1px solid var.$color-electric-blue;
            }
            70% {
              color: var.$color-white;
              background-color: var.$color-electric-blue;
            }
            85% {
              padding: 5px 4px 5px 6px;
            }
            100% {
              padding: 5px;
              border: 1px solid var.$color-electric-blue;
              color: var.$color-electric-blue;
              background-color: var.$color-white;
            }
          }

          &-clicked {
            .pd-faq-menu-desktop-chevron {
              animation: chevronRightClick ease-out 400ms;
            }
          }
        }

        .pd-faq-menu-desktop-chevron {
          display: flex;
          align-items: center;
          justify-content: center;
          background: none;
          border: 1px solid transparent;
          border-radius: 9999px;
          color: var.$color-electric-blue;
          padding: 5px;
          cursor: pointer;

          &:hover {
            transition: border-color 200ms ease-out;
            border: 1px solid var.$color-electric-blue;
          }

          &:focus {
            outline: none !important;
          }
        }
      }

      .pd-faq-menu-desktop-menu-items {
        display: flex;
        align-items: center;
        overflow-x: auto;
        font-family: var.$font-figtree;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        text-align: left;
        color: #A0A0A0;
        text-transform: uppercase;
        scrollbar-width: none; /* Hide scrollbar in Firefox */
        -ms-overflow-style: none; /* Hide scrollbar in IE/Edge */
        position: relative;

        .pd-faq-desktop-item {
          min-width: 166px;
          padding-right: 66px;
          text-align: center;
          padding-top: 8px;
          padding-bottom: 8px;
          border-bottom: 2px solid #EEEEEE;
          .pd-faq-desktop-item-underline {
            display: none;
          }

          .pd-faq-desktop-item-url {
            &.is-clicked {
              color: var(--Avail-Darkest, #1E2828);
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }

            &:hover {
              color: var.$color-electric-blue;
              cursor: pointer;
            }
          }

          &:last-child {
            min-width: 100px;
            padding-right: 0;
          }
        }

        #pd-faq-desktop-item-underline {
          display: inline-block;
          position: absolute;
          left: 0;
          bottom: 0;
          height: 2px;
          background-color: var.$color-electric-blue;
          transition: left ease-out 300ms;
        }
      }
    }
  }

  .pd-faq-subject {
    display: flex;
    flex-direction: column;
    gap: 0;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 32px;
    padding-bottom: 64px;

    &#fee_and_claims {
      padding-bottom: 120px;
    }

    .pd-faq-title {
      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
      border-bottom: 0.76px solid #D6E0E2;
      padding-bottom: 24px;
      color: #363131;
      word-break: break-all;
      text-align: start;

      @media screen and (min-width: 1024px) {
        border-bottom: none;
        padding-bottom: 0;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        min-width: 218px;
        width: 300px;
        max-width: 300px;
        min-width: 300px;
        flex: 1;
      }
    }

    .pd-faq-content {
      .pd-faq-accordion {
        padding: 0 !important;

        .pd-faq-accordion-content {
          font-family: var.$font-figtree;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.005em;
          text-align: left;
          color: var.$color-gray-2;
          padding-bottom: 24px;

          a {
            color: var.$color-electric-blue;
            text-decoration: underline;
          }
        }

        .pd-service-accordion-header {
          gap: 2.4rem;
          
          .pd-service-accordion-header-title {
            color: #1A1A1A !important;
            font-size: 18px !important;
            line-height: 24px;
          }
        }
      }

      @media screen and (min-width: 1024px) {
        flex-grow: 1;
        max-width: 70%;
        display: flex;
        flex-direction: column;
        gap: 24px;
      }

      @media screen and (min-width: 1200px) {
        max-width: 80%;

        .pd-faq-accordion {
          .pd-service-accordion-header {
            padding-top: 0;
            padding-bottom: 12px;
          }
        }
      }
    }

    @media screen and (min-width: 1024px) {
      flex-direction: row;
      align-items: start;

      .pd-faq-content {
        border-left: 0.761px solid #D6E0E2;
        padding-left: 4rem;
      }
    }
  }
}

.pd-faq-dropdown {
  position: relative;
  width: 100%;
  height: 0;

  .pd-faq-dropdown-chosen-value,
  .pd-faq-dropdown-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .pd-faq-dropdown-icon {
    background-color: var.$color-white;
    position: absolute;
    top: 15px;
    right: 15px;
    color: #667085;
    display: flex;
    align-items: center;
    justify-content: center;

    &.is-opened {
      transform: rotate(180deg) !important;
    }
  }

  .pd-faq-dropdown-chosen-value {
    text-transform: uppercase;
    height: 50px;
    width: 100%;
    padding: 1rem;
    background-color: var.$color-white;
    border: 1px solid #D0D5DD;
    transition: .3s ease-in-out;
    font-family: var.$font-poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #6C7475;

    &::-webkit-input-placeholder {
      color: #6C7475;
    }

    &::after {
      content: 'hehe';
      display: inline-block;
      width: 12px;
      height: 12px;
    }

    &::-webkit-input-placeholder {
      color: #333;
    }

    &:hover {
      border-color: var.$color-light-blue;
      cursor: pointer;
    }

    &:focus,
    &.is-opened {
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
      outline: 0;
      border-color: var.$color-electric-blue;
      color: #000;

      &::-webkit-input-placeholder {
        color: #000;
      }
    }
  }

  .pd-faq-dropdown-content {
    list-style: none;
    margin-top: 4rem;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
    overflow: hidden;
    max-height: 0;
    transition: .3s ease-in-out;
    background-color: var.$color-white;
    padding-inline-start: 0;

    &.is-opened {
      max-height: 320px;
      overflow: auto;
    }

    li {
      position: relative;
      height: 4rem;
      padding: 1rem;
      font-size: 1.1rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: background-color .3s;
      opacity: 1;

      a {
        color: var.$color-gray-3;
      }

      &:hover {
        background-color: var.$color-electric-blue;
        color: var.$color-white;
      }

      &.is-closed {
        max-height: 0;
        overflow: hidden;
        padding: 0;
        opacity: 0;
      }
    }
  }
}
