@use '@styles/settings/_variables.scss' as var;

.pd-our-team {
  background-color: var.$color-light-blue-2;
  padding-bottom: 120px;

  .pd-our-team-container {
    background-color: var.$color-white;
    border-radius: 20px;
    gap: 32px;
    padding: 64px 20px;

    .pd-our-team-text {
      text-align: center;
      margin-bottom: 32px;

      .pd-our-team-text-title {
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: 0.05em;
        margin-bottom: 24px;
      }

      .pd-our-team-text-description {
        font-size: 18px;
        font-weight: 300;
        line-height: 27px;
        letter-spacing: 0em;

        span {
          color: #000;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .pd-swiper-our-team .swiper-slide {
      height: auto;
      align-items: stretch;
    }
  }

  @media screen and (min-width: 1024px) {
    padding-top: 120px;

    .pd-our-team-container {
      gap: 48px;
      padding: 96px 0;

      .pd-our-team-text {
        padding: 0 135px;
        margin-bottom: 48px;

        .pd-our-team-text-title {
          font-size: 32px;
          line-height: 48px;
        }
      }
    }
  }
}

.pd-our-team-member-card {
  text-align: center;
  width: 100%;
  max-width: 340px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  align-items: center;
  justify-content: flex-start;
  padding: 32px;
  border: 1px solid var.$color-off-white;
  border-radius: 10px;
  margin-bottom: 50px;

  .pd-our-team-member-avatar {
    width: 155px !important;
    height: 155px !important;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;

    img {
      width: 155px !important;
      height: 155px !important;
    }
  }

  .list {
    padding: 0;
    text-align: left;
  }

  .pd-our-team-member-name {
    font-size: 22px;
    font-weight: 500;
    line-height: 29px;
  }

  .pd-our-team-member-description {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    min-height: 170px;
    display: flex;
    align-items: center;
  }

  @media (min-width: 1024px) {
    width: 340px;
  }
}

.pd-swiper-our-team {
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    width: 340px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-pagination-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
    height: 40px;
  }

  .swiper-pagination {
    position: relative;
    bottom: 0;
    top: 0;
    height: 40px;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .swiper-pagination-bullet {
    background-color: var.$color-black;
    width: 8px;
    height: 8px;
    margin: 0 8px !important;
    transition: 0.1s;
    opacity: 1;

    &:hover {
      cursor: pointer;
      background-color: var.$color-gray;
    }
  }

  .swiper-pagination-bullet-active {
    outline: 1.5px solid var.$color-black;
    outline-offset: 5px;

    &:hover {
      outline-color: var.$color-gray;
    }
  }

  .swiper-button-prev {
    position: relative;
    top: 0;
    bottom: 0;
    color: #000000;
    margin-top: 0;
    width: 40px;
    height: 40px;
    &::after {
      display: none;
    }
  }

  .swiper-button-next {
    position: relative;
    margin-top: 0;
    top: 0;
    bottom: 0;
    color: #000000;
    width: 40px;
    height: 40px;
    &::after {
      display: none;
    }
  }
}

.pd-our-team-member-drawer {
  display: flex;
  justify-content: flex-end;
  transition: 0ms;

  &.opacity-0 {
    transition: 0ms;
    display: none;
  }

  .pd-drawer-header {
    display: flex;
    justify-content: flex-end;
  }

  .pd-drawer {
    &-container {
      width: 100%;

      @media screen and (min-width: 1024px) {
        width: 1050px;
      }
    }

    &-header {
      border-bottom: 1px solid var.$color-primary-50;
      padding: 9px 16px;
    }

    &-body {
      padding: 0;
      height: calc(100% - 266px);
    }

    &-body-inner {
      width: 100%;
      padding: 0;
    }

    &-footer {
      align-items: center;
      padding: 24px 24px;
      border-top: 0;
      gap: 0;
      border-top: 1px solid var.$color-primary-50;

      @media screen and (min-width: 1024px) {
        padding: 32px 32px;
      }
    }
  }

  &.is-hidden {
    display: none;
  }

  .pd-selector {
    padding: 0 24px;
  }
}

.pd-member-drawer-detail {
  padding: 20px;
  overflow: auto;
  height: calc(100vh - 160px);

  @media screen and (min-width: 1024px) {
    padding: 48px;
    height: calc(100vh - 170px);
  }

  .member-bio {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #c1c1c1;

    .member-title-and-avatar {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .member-avatar {
        height: 98px;
        width: 98px;
        border-radius: 9999px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          object-fit: cover;
        }
      }

      .member-name-identity {
        display: flex;
        flex-direction: column;
        gap: 4px;
        letter-spacing: 0.05em;
        text-align: left;

        .member-name {
          font-size: 22px;
          font-weight: 500;
          line-height: 33px;
        }

        .member-title {
          font-size: 16px;
          font-weight: 300;
          line-height: 24px;
        }
      }
    }

    .member-description {
      white-space: break-spaces;
    }

    @media screen and (min-width: 1024px) {
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 32px;

      .member-title-and-avatar {
        gap: 16px;
        max-width: 30%;

        .member-avatar {
          height: 272px;
          width: 272px;

          img {
            height: 272px;
            width: 272px;
          }
        }

        .member-name-identity {
          gap: 16px;

          .member-name {
            font-size: 18px;
            line-height: 27px;
          }
        }
      }
    }
  }

  .member-qualifications {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 18px;
    text-align: left;

    .member-qualifications-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    ul {
      li {
        font-size: 16px;
        font-style: italic;
        font-weight: 300;
        line-height: 24px;
      }
    }

    @media screen and (min-width: 1024px) {
      padding-top: 32px;
    }
  }
}

.pd-our-team-drawer-cta-mobile {
  padding: 10px !important;

  @media screen and (min-width: 1024px) {
    display: none;
  }
}

.pd-our-team-drawer-cta-desktop {
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
  }
}

.pd-our-team-carousel {
  @media screen and (min-width: 1024px) {
    padding: 0 20px;
  }
}
