@use '@styles/settings/_variables.scss' as var;

.pd-vanity-builder {
  position: relative;
  background-color: var.$color-body-bg;
  overflow: hidden;

  * {
    box-sizing: border-box !important;
  }

  &-inner {
    display: flex;
    flex-wrap: wrap;
  }

  &__column-left {
    width: 100%;
    @media screen and (min-width: var.$breakpoint-tablet) {
      width: 60%;
      padding: 56px 32px 56px 0;
    }
  }

  &__column-right {
    position: relative;
    background-color: white;
    width: 100%;
    @media screen and (min-width: var.$breakpoint-tablet) {
      width: 40%;
      padding: 56px 0 56px 32px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100vw;
      background-color: white;
      @media screen and (max-width: var.$breakpoint-phone-max) {
        left: -10px;
      }
    }
  }
}