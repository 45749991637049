@use '@styles/settings/_variables.scss' as var;

.pd-tabs {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 14px;
  max-width: 100%;

  &__tabs {
    display: flex;
    gap: 16px;
    margin: 0;
    padding: 0 0 10px 0;
    max-width: 100%;
    overflow-x: auto;

    li {
      display: flex;
    }

    a {
      background-color: var.$color-body-bg;
      border-radius: 3px;
      padding: 2px 8px;
      color: var.$color-base;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.5;
      white-space: nowrap;
      cursor: pointer;

      &.is-active {
        background-color: var.$color-secondary;
      }
    }
  }

  &__content {
    display: flex;
    max-width: 100%;
    transition: opacity 1s, transform 1s;
  }

  &__content:not(.is-active) {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-50px);
    z-index: -10;
  }
}