@use '@styles/settings/_variables.scss' as var;

.map {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding-top: 72px;
  padding-bottom: 48px;

  a {
    width: 100%;
  }

  p {
    color: var.$color-black;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  @media screen and (min-width: 1024px) {
    padding-top: 88px;
    padding-bottom: 88px;
  }

  iframe {
    width: 100%;
    border-radius: 20px;
    max-height: 390px;

    @media screen and (min-width: 1024px) {
      max-width: 1340px;
    }
  }
}