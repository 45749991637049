@use '@styles/settings/_variables.scss' as var;

.pd-cart-drawer {
  justify-content: flex-end;

  .pd-drawer {
    &-container {
      width: 463px;
    }

    &-header {
      border-bottom: 0;
      padding: 9px 16px;
    }

    &-body {
      padding: 0;
      height: calc(100% - 266px);
    }

    &-body-inner {
      width: 100%;
      padding: 0;
    }

    &-footer {
      flex-direction: column;
      align-items: center;
      padding: 0;
      border-top: 0;
      height: 160px;
      gap: 0;

      &__buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 12px 24px;
        width: 100%;
        gap: 24px;
      }

      .pd-button {
        width: 100%;
        padding: 7px 41px;
      }
    }
  }

  &:not(.is-open) {
    .pd-drawer-container {
      transform: translateX(100%);
    }
  }

  .pd-selector {
    padding: 0 24px;
  }

  .pd-cart-summary {
    width: 100%;
    height: 58px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;
    background-color: var.$color-body-bg;
    font-size: 12px;
  }
}