@use '@styles/settings/_variables.scss' as var;

.pd-service {
  background-color: var.$color-light-blue-2;

  &-bottom-line {
    color: var.$color-electric-blue;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.05em;

    margin-top: 42px;

    @media screen and (min-width: 768px) {
      margin-top: 88px;

      font-size: 32px;
      line-height: 48px;
      letter-spacing: 0.05em;
      text-align: center;
    }

    .desktop {
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}
