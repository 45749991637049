@use '@styles/settings/_variables.scss' as var;

.pd-products-carousel {
  position: relative;
  width: 100%;
  @media screen and (max-width: var.$breakpoint-phone-max) {
    padding-bottom: 84px;
  }

  .swiper-wrapper {
    box-sizing: border-box;
  }

  .swiper-slide {
    width: 25%;
    min-height: 100%;
    @media screen and (max-width: var.$breakpoint-phone-max) {
      width: 220px;
    }
  }

  .swiper-button {
    border-radius: 3px;
    background-color: var.$color-secondary;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 30%;
    @media screen and (max-width: var.$breakpoint-phone-max) {
      top: unset;
      bottom: 24px;
    }

    &::after {
      color: #3F515B;
      font-size: 10px;
      font-weight: 700;
    }

    &-lock {
      display: none;
    }

    &-prev {
      left: -12px;
      @media screen and (max-width: var.$breakpoint-phone-max) {
        left: 0;
      }
    }

    &-next {
      right: -12px;
      @media screen and (max-width: var.$breakpoint-phone-max) {
        right: 0;
      }
    }
  }
}