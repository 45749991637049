@use '@styles/settings/_variables.scss' as var;

@keyframes scrollText {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(-100%, 0, 0);
  }
}

.pt-48 {
  padding-top: 48px;
}

.text-left {
  text-align: left;
}

.mt-24 {
  margin-top: 24px;
}

.pd-hero-banner {
  padding-top: 50px;
  padding-bottom: 50px;

  .pd-hero-banner-container {
    background-color: var.$color-white;
    border-radius: 20px;

    .pd-hero-banner-text-container {
      padding: 64px 20px;
      display: flex;
      flex-direction: column;
      gap: 48px;

      &.contact-us {
        gap: 46px;
      }

      .pd-hero-banner-heading {
        text-align: center;
        letter-spacing: 0.05em;
        font-size: 24px;
        font-weight: 500;
        word-break: break-word;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        .pd-hero-banner-heading-tag {
          display: inline;
          padding: 5px 15px;
          border-radius: 100px;
          color: var.$color-electric-blue;
          border: 1.5px solid var.$color-electric-blue;
          margin: 8px 0;
        }

        &.contact-us {
          font-size: 20px;
          line-height: normal;

          .small-device-only {
            @media screen and (min-width: 550px) {
              display: none;
            }
          }

          p {
            font-weight: 500;
          }

          a {
            color: var.$color-electric-blue;
            text-decoration: underline;
          }
        }
      }
      .pd-hero-banner-body {
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0em;
        text-align: center;
        line-height: 27px;

        &.center {
          margin: auto;
          text-align: center;
        }

        &.contact-us {
          font-size: 16px;
          max-width: 860px;
          margin: auto;
        }
      }
      .pd-hero-banner-cta-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .pd-hero-banner-button {
        font-family: var.$font-poppins;
        background: var.$color-electric-blue;
        height: 50px;
        border-radius: 100px;
        padding: 0 20px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.1em;
        border: 0;
        color: var.$color-white;
        max-width: max-content;
        margin: 0 auto;
        cursor: pointer;

        &:hover {
          border: 3px solid var.$color-light-blue-3;
        }
      }


      .pd-hero-banner-button:active,
      .pd-hero-banner-button:focus  {
        border: 0;
        outline: 0;
        box-shadow: 0;
      }
    }

    .pd-hero-banner-scroll-text {
      color: var.$color-dark-blue;
      background-color: var.$color-skin;
      padding: 18px 0;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      overflow: hidden;
      display: flex;
      gap: 32px;

      .pd-hero-banner-scroll-text-container {
        display: flex;
        gap: 32px;
        white-space: nowrap;
        animation: scrollText 20s infinite linear;

        .pd-hero-banner-scroll-text-item {
          text-transform: uppercase;
          font-size: 18px;
          font-weight: 500;
          line-height: 27px;
          letter-spacing: 0.02em;
          text-align: left;
        }
      }
    }

    .pd-hero-banner-scroll-text-01 {
      color: var.$color-white;
      background-color: var.$color-dark-blue;
    }
  }

  @media screen and (min-width: 1024px) {
    padding-top: 88px;
    padding-bottom: 88px;

    &.banner-footer {
      padding-bottom: 120px;
      padding-top: 32px;
    }

    .pd-hero-banner-container {
      .pd-hero-banner-text-container {
        padding: 88px 50px;

        .pd-hero-banner-heading {
          font-size: 32px;

          .pd-hero-banner-heading-tag {
            padding: 20px 24px;
            margin: 10px 0;
          }

          &.contact-us {
            font-size: 32px;
            font-style: normal;
            line-height: normal;
          }
        }
        .pd-hero-banner-body {
          padding: 0 10px;
          font-size: 24px;
          line-height: 36px;

          &.contact-us {
            font-size: 18px;
          }
        }

        .pd-hero-banner-cta-container {
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 24px;
        }
      }

      .pd-hero-banner-scroll-text {
        padding: 24px 0;
      }
    }
  }
}

.pd-about-us-container {
  .pd-hero-banner {
    .pd-hero-banner-container {
      .pd-hero-banner-text-container {
        gap: 24px;

        @media screen and (min-width: 1024px) {
          gap: 32px;
        }
      }
    }
  }
}

.pd-service-container {
  .pd-hero-banner {
    .pd-hero-banner-container {
      .pd-hero-banner-text-container {
        gap: 24px;

        @media screen and (min-width: 1024px) {
          gap: 32px;
        }
      }
    }
  }
}

.small-break {
  @media (max-width: 790px) {
    display: none;
  }
}